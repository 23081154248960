import { Button, Input, InputNumber, Popconfirm, Space, Table, Tooltip } from "antd";
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from "react";
import CurriculumGradeAPI from '../../api/CurriculumGradeAPI';
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

/**
 * @param {Object} props
 * @param {number} props.classId
 * @param {number} props.curriculumId
 * @param {'admin'|'teacher'} props.role - user role
 */
export default function CurriculumGradeTable({
  classId,
  curriculumId,
  role,
}) {
  // const [students, setStudents] = useState([])
  const [grades, setGrades] = useState([])
  const [gradeTypes, setGradeTypes] = useState([])
  const fetchData = useCallback(async () => {
    let gradeRes, gradeTypeRes
    switch (role) {
      case 'teacher':
        gradeTypeRes = await CurriculumGradeAPI.teacherGetCurriculumGradeTypes({
          class_id: classId,
          curriculum_id: curriculumId,
        })
        gradeRes = await CurriculumGradeAPI.teacherGetStudentCurriculumGradesByClass({
          class_id: classId,
          curriculum_id: curriculumId,
        })
        break;
      default:
        break;
    }
    setGrades(gradeRes)
    setGradeTypes(gradeTypeRes)
  }, [classId, curriculumId, role])
  useEffect(() => {
    fetchData()
  }, [fetchData])

  const saveGradeType = useCallback(async (gradeType) => {
    gradeType = {
      ...gradeType,
      class_id: classId,
      curriculum_id: curriculumId,
    }
    try {
      await CurriculumGradeAPI.teacherUpsertCurriculumGradeType(gradeType)
    } catch (error) {}
    fetchData()
  }, [classId, curriculumId, fetchData])
  const deleteGradeType = useCallback(async (gradeType) => {
    await CurriculumGradeAPI.teacherDeleteCurriculumGradeType(gradeType)
    fetchData()
  }, [fetchData]);

  const saveGrade = useCallback(async (grade) => {
    try {
      await CurriculumGradeAPI.teacherUpsertCurriculumGrade(grade)
    } catch (error) {}
    fetchData()
  }, [fetchData])

  const columns = useMemo(() => [
    {
      title: 'STT',
      render: (text, record, index) => index + 1,
      width: 50,
    },
    {
      title: 'Học sinh',
      dataIndex: ['student', 'full_name'],
      width: 200,
    },
    ...gradeTypes.map((gradeType, gradeIdx) => ({
      ellipsis: true,
      title: () => {
        const title = `${gradeType.type} (${gradeType.weight})`
        return <Tooltip title={title}>
          <span style={{ cursor: 'pointer', padding: 3 }} onClick={() => { setEditGradeType({ ...gradeType }); setShowEditGradeType(true) }}>
            <EditOutlined />
          </span>
          <Popconfirm
            title="Chắc chắn xoá?"
            onConfirm={() => deleteGradeType(gradeType)}
            okText="Yes"
            cancelText="No"
          >
            <span style={{ cursor: 'pointer', padding: 3 }}>
              <DeleteOutlined />
            </span>
          </Popconfirm>
          {title}
        </Tooltip>
      },
      render: (text, record, rowIdx) => {
        const grade = record.grades.find(g => g.type_id === gradeType.id)
        return <InputNumber
          min={0}
          max={10}
          defaultValue={grade?.value}
          controls={false}
          keyboard={false}
          onBlur={(e) => {
            const isNull = e.target.value === ''
            setTimeout(() => {
              const value = isNull ? null : +e.target.value
              if (value === grade?.value) return
              saveGrade({
                id: grade?.id,
                value,
                student_id: record.student.id,
                type_id: gradeType.id,
              })
            });
          }}
          id={`grade-${rowIdx}-${gradeIdx}`}
          onPressEnter={(e) => {
            // focus to next student grade
            const d = e.shiftKey ? -1 : 1
            const nextIdx = rowIdx + d
            if (grades[nextIdx]) {
              const input = document.getElementById(`grade-${nextIdx}-${gradeIdx}`)
              if (input) input.focus()
            }
          }}
        />
      },
      width: 100,
    }))
  ], [gradeTypes, deleteGradeType, saveGrade, grades])

  const [showEditGradeType, setShowEditGradeType] = useState(false)
  const [editGradeType, setEditGradeType] = useState({ type: '', weight: null })
  const onSaveGradeType = useCallback(() => {
    if (!editGradeType.type || !editGradeType.weight) return
    if (gradeTypes.find(g => g.id !== editGradeType.id && g.type === editGradeType.type)) return
    saveGradeType(editGradeType)
    setShowEditGradeType(false)
    setEditGradeType({ type: '', weight: null })
  }, [saveGradeType, gradeTypes, editGradeType])

  return (
    <div>
      <Space direction="vertical">
        <Button type="primary" onClick={() => setShowEditGradeType(v => !v)}>Thêm điểm</Button>
        {showEditGradeType && (
          <Space>
            <Input
              placeholder="Loại điểm"
              value={editGradeType.type}
              onChange={(e) => setEditGradeType(t => ({ ...t, type: e.target.value }))}
            />
            <InputNumber
              placeholder="Trọng số (0-1)"
              step={0.1}
              min={0}
              max={1}
              value={editGradeType.weight}
              size="large"
              style={{ width: 130 }}
              onChange={(v) => setEditGradeType(t => ({ ...t, weight: v }))}
            />
            <Button onClick={() => setShowEditGradeType(false)}>Hủy</Button>
            <Button type="primary" disabled={!editGradeType.type || !editGradeType.weight} onClick={() => onSaveGradeType()}>Lưu</Button>
          </Space>
        )}
      </Space>
      <Table
        dataSource={grades}
        columns={columns}
        rowKey={record => record.student.id}
        pagination={false}
        size="small"
        scroll={{ y: '90vh', x: 700 }}
      />
    </div>
  )
}
