import { useCallback, useMemo, useState, useRef, useEffect } from 'react'
import debounce from 'lodash/debounce'

const SELECT_ALL_OPTION = { label: 'Select All', value: '_SELECT_ALL_OPTION' }

function useSelectAllOption(
  fetchOptions,
  debounceTimeout = 300,
  initialFetch = true
) {
  const [fetching, setFetching] = useState(false)
  const [options, setOptions] = useState([])
  const fetchRef = useRef(0)

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return
        }

        setOptions(newOptions)
        setFetching(false)
      })
    }

    return debounce(loadOptions, debounceTimeout)
  }, [fetchOptions, debounceTimeout])

  useEffect(() => {
    debounceFetcher.cancel()
  }, [debounceFetcher])

  useEffect(() => {
    if (initialFetch) {
      debounceFetcher('')
      debounceFetcher.flush()
    }
    return () => {
      debounceFetcher.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const optionsWithAllOption = useMemo(
    () => [SELECT_ALL_OPTION, ...options],
    [options]
  )

  /** pass this to Form.Item's getValueFromEvent prop */
  const getValueFromEvent = useCallback(
    (value, selections) => {
      if (!selections?.length) return selections.map((o) => o.value)
      if (!selections?.some((s) => s.value === SELECT_ALL_OPTION.value)) {
        return selections.map((o) => o.value)
      }
      const labelInValue = typeof value[0]?.label === 'string'
      // if "Select All" option selected, set value to all options
      // also keep labelInValue in consideration
      return labelInValue ? options : options.map((o) => o.value)
    },
    [options]
  )
  return [getValueFromEvent, optionsWithAllOption, fetching, debounceFetcher]
}
export default useSelectAllOption
