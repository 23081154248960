import ApiSender from './ApiSender'

export default class TeacherAPI {
  static getTeachers(query) {
    const route = '/core/admin/teachers'
    return ApiSender.get(route, query)
  }
  static createTeacher(data) {
    const route = '/core/admin/teachers'
    return ApiSender.post(route, data)
  }
  static getTeacher(data) {
    const route = '/core/admin/teachers/' + data?.id
    return ApiSender.get(route)
  }
  static updateTeacher(data) {
    const route = '/core/admin/teachers/' + data.id
    return ApiSender.put(route, data)
  }
  static deleteTeacher(data) {
    const route = '/core/admin/teachers/' + data?.id
    return ApiSender.delete(route)
  }
  static teacherCheckIn(data) {
    const route = '/core/teacher/calendars/check-in/' + data?.id
    return ApiSender.put(route)
  }
  static teacherCheckOut(data) {
    const route = '/core/teacher/calendars/check-out/' + data?.id
    return ApiSender.put(route)
  }
  static teacherCreateStudent(data) {
    const route = '/core/teacher/students'
    return ApiSender.post(route, data)
  }
  static teacherUpdateStudent(data) {
    const route = '/core/teacher/students/' + data?.id
    return ApiSender.put(route, data)
  }
  static teacherDeleteStudent(data) {
    const route = '/core/teacher/students/' + data?.id
    return ApiSender.delete(route)
  }
  static teacherGetStudents(query) {
    const route = '/core/teacher/students'
    return ApiSender.get(route, query)
  }
}
