import React, { useState, useEffect } from 'react'
import { Button, Modal, Form, Checkbox, notification } from 'antd'
import './style.css'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import DebounceSelect from '../DebounceSelect'
import { fetchTeachers } from './event-utils'
import { invalidTime, getEpochTime, formatDate } from '../../utils/time'
import CalendarAPI from '../../api/CalendarAPI'
import TeacherAPI from '../../api/TeacherAPI'

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
}

const RootCalendar = ({
  calendars,
  fetchInitData,
  fetchCurriculumUnits,
  onlyView = false,
  onlyEdit = false,
}) => {
  const [form] = Form.useForm()
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState('ADD')
  const [selectTimeRangeToCreate, setSelectTimeRangeToCreate] = useState({})
  const [selectedEvent, setSelectedEvent] = useState({})
  const [time, setTime] = useState({})
  const [range, setRange] = useState()

  const onCancel = () => {
    setShowModal(false)
    setSelectTimeRangeToCreate({}) // reset selectTimeRangeToCreate
    setSelectedEvent({}) // reset selectedEvent
  }
  const handleDateSelect = (selectTimeRangeToCreate) => {
    if (onlyView || onlyEdit) return
    setShowModal(true)
    setModalType('ADD')
    setSelectTimeRangeToCreate(selectTimeRangeToCreate)
  }
  const handleEventClick = (selectedEvent) => {
    setShowModal(true)
    setModalType('EDIT')
    setSelectedEvent(selectedEvent)
  }

  const createEvent = (values) => {
    CalendarAPI.createCalendar(values)
      .then((res) => {
        notification['success']({
          message: 'Success',
          description: res.message
        })
      })
      .catch((err) => {})
      .finally(() => {
        fetchInitData(range)
        onCancel()
      })
  }
  const updateEvent = (values) => {
    CalendarAPI.updateCalendar(values)
      .then((res) => {
        notification['success']({
          message: 'Success',
          description: res.message
        })
      })
      .catch((err) => {})
      .finally(() => {
        fetchInitData(range)
        onCancel()
      })
  }

  const onSave = () => {
    form.validateFields().then((values) => {
      // create new event
      if (selectTimeRangeToCreate.view) {
        // let calendarApi = selectTimeRangeToCreate.view.calendar
        // calendarApi.unselect()
        if (selectTimeRangeToCreate) {
          // call api create event
          createEvent({
            curriculum_unit_id: values.curriculum_unit_id,
            start_time: getEpochTime(selectTimeRangeToCreate.startStr),
            end_time: getEpochTime(selectTimeRangeToCreate.endStr),
            teacher_id: values.teacher_id
          })
        }
      }
      //update event
      if (selectedEvent.event) {
        updateEvent({
          id: selectedEvent.event.id,
          curriculum_unit_id: values.curriculum_unit_id,
          // start_time: getEpochTime(selectTimeRangeToCreate.startStr),
          // end_time: getEpochTime(selectTimeRangeToCreate.endStr),
          teacher_id: values.teacher_id
        })
      }
    })
  }

  const onDelete = async () => {
    await CalendarAPI.deleteCalendar({ id: selectedEvent.event.id })
      .then((res) => {
        notification['success']({
          message: 'Success',
          description: res.message
        })
      })
      .catch((err) => {})
      .finally(() => {
        fetchInitData(range)
        onCancel()
      })
  }
  const handleCheckIn = async (e) => {
    if (e.target.checked) {
      await TeacherAPI.teacherCheckIn({ id: selectedEvent.event.id })
        .then((res) => {
          notification['success']({
            message: 'Success',
            description: res.message
          })
        })
        .catch((err) => {})
        .finally(() => {
          fetchInitData(range)
          onCancel()
        })
    }
  }
  const handleCheckOut = async (e) => {
    if (e.target.checked) {
      await TeacherAPI.teacherCheckOut({ id: selectedEvent.event.id })
        .then((res) => {
          notification['success']({
            message: 'Success',
            description: res.message
          })
          fetchInitData(range)
          onCancel()
        })
        .catch((err) => {
          e.target.checked = false
        })
        .finally(() => {})
    }
  }

  useEffect(() => {
    switch (true) {
      case !!selectTimeRangeToCreate.startStr: {
        setTime({
          start: selectTimeRangeToCreate.startStr,
          end: selectTimeRangeToCreate.endStr
        })
        break
      }
      case !!selectedEvent.event: {
        setTime({
          start: selectedEvent.event.startStr,
          end: selectedEvent.event.endStr
        })
        form.setFieldsValue({
          curriculum_unit_id:
            selectedEvent.el.fcSeg.eventRange.def.extendedProps
              .curriculum_unit_id,
          teacher_id:
            selectedEvent.el.fcSeg.eventRange.def.extendedProps.teacher_id,
          teacher_check_in:
            !!selectedEvent.el.fcSeg.eventRange.def.extendedProps
              .teacher_check_in,
          teacher_check_out:
            !!selectedEvent.el.fcSeg.eventRange.def.extendedProps
              .teacher_check_out
        })
        break
      }
      default: {
        form.resetFields()
      }
    }
  }, [form, selectTimeRangeToCreate, selectedEvent])

  useEffect(() => {
    if (!fetchInitData || !range?.start) return;
    fetchInitData(range)
  }, [fetchInitData, range])

  return (
    <div>
      <div>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay'
          }}
          initialView="timeGridWeek"
          nowIndicator
          editable={false}
          selectable={!onlyView && !onlyEdit}
          selectMirror={true}
          dayMaxEvents={true}
          events={calendars}
          select={handleDateSelect}
          eventContent={renderEventContent} // custom render function
          eventClick={handleEventClick}
          datesSet={setRange}
        />
      </div>
      <Modal
        title={modalType === 'ADD' ? 'Add new event' : `Editing event`}
        open={showModal}
        onCancel={onCancel}
        onClose={onCancel}
        footer={[
          modalType === 'EDIT' &&
          selectTimeRangeToCreate &&
          !onlyView &&
          !onlyEdit && (
            <Button
              danger
              key="delete"
              type="primary"
              onClick={onDelete}
              disabled={modalType === 'ADD' || onlyView || onlyEdit}
            >
              Delete
            </Button>
          ),
          <Button key="back" onClick={onCancel}>
            Cancel
          </Button>,
          !onlyView && !onlyEdit && (
            <Button
              key="submit"
              type="primary"
              disabled={onlyView}
              onClick={() => form.submit()}
            >
              Save
            </Button>
          )
        ]}
      >
        <Form form={form} {...layout} onFinish={onSave}>
          <Form.Item label="Start time">{formatDate(time.start)}</Form.Item>
          <Form.Item label="End time"> {formatDate(time.end)}</Form.Item>
          {!onlyEdit && (
            <Form.Item
              label="Teacher"
              name="teacher_id"
              rules={[
                {
                  required: true,
                  message: 'Please input Teacher unit!'
                }
              ]}
            >
              <DebounceSelect
                placeholder="Select Teacher"
                fetchOptions={fetchTeachers}
                options
                allowClear
                disabled={onlyView || onlyEdit}
              />
            </Form.Item>
          )}

          <Form.Item
            label="Curriculum unit"
            name="curriculum_unit_id"
            rules={[
              {
                required: true,
                message: 'Please input curriculum unit!'
              }
            ]}
          >
            <DebounceSelect
              placeholder="Select curriculum unit"
              fetchOptions={fetchCurriculumUnits}
              allowClear
              disabled={onlyView || onlyEdit}
            />
          </Form.Item>
          <Form.Item
            label="Start"
            name="teacher_check_in"
            valuePropName="checked"
          >
            <Checkbox
              disabled={invalidTime(time.start) || !onlyEdit}
              onChange={handleCheckIn}
            />
          </Form.Item>
          <Form.Item
            label="End"
            name="teacher_check_out"
            valuePropName="checked"
          >
            <Checkbox
              disabled={invalidTime(time.end) || !onlyEdit}
              onChange={handleCheckOut}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}
export default RootCalendar
const renderEventContent = (eventInfo) => {
  const label = new RegExp(`a`).test(eventInfo.timeText)
    ? eventInfo.timeText + 'm '
    : eventInfo.timeText
  return (
    <div className="truncate">
      <span>{label} </span>
      <span>{eventInfo.event.title}</span>
    </div>
  )
}
