import ApiSender from './ApiSender'

export default class ClassAPI {
  static getClasses(query) {
    const route = '/core/admin/classes'
    return ApiSender.get(route, query)
  }
  static createClass(data) {
    const route = '/core/admin/classes'
    return ApiSender.post(route, data)
  }
  static getClass(data) {
    const route = '/core/admin/classes/' + data?.id
    return ApiSender.get(route)
  }
  static updateClass(data) {
    const route = '/core/admin/classes/' + data.id
    return ApiSender.put(route, data)
  }
  static deleteClass(data) {
    const route = '/core/admin/classes/' + data?.id
    return ApiSender.delete(route)
  }
  static teacherGetClasses(query) {
    const route = '/core/teacher/classes'
    return ApiSender.get(route, query)
  }
  static teacherGetClass(data) {
    const route = '/core/teacher/classes/' + data?.id
    return ApiSender.get(route)
  }
}
