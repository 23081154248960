import {
  Button,
  Card,
  Col,
  Form,
  Input,
  List,
  Modal,
  Popconfirm,
  Row,
  Space,
  Table,
  Upload,
  message,
  notification
} from 'antd'

import {
  UploadOutlined
} from '@ant-design/icons'
import _ from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import CurriculumAPI from '../../api/CurriculumAPI'
import CurriculumMaterialAPI from '../../api/CurriculumMaterialAPI'
import CurriculumUnitAPI from '../../api/CurriculumUnitAPI'
import UploadAPI from '../../api/UploadAPI'
import DebounceSelect from '../../components/DebounceSelect'
import MaterialViewModal from '../../components/MaterialViewModal'
import { preview_extension } from '../../const'

const { Search } = Input

function CurriculumUnit() {
  const [form] = Form.useForm()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [modalType, setModalType] = useState('ADD')
  const [selected, setSelected] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(0)
  const [search, setSearch] = useState('')
  // const [addMaterialModal, setAddMaterialModal] = useState(false)
  const [visibleSlideModal, setVisibleSlideModal] = useState(false)

  const fetchData = useCallback(
    async (query) => {
      setLoading(true)
      CurriculumUnitAPI.getCurriculumUnits({
        page_number: query?.page_number || pageNumber,
        page_size: query?.page_size || pageSize,
        search: query?.search || search
      })
        .then((res) => {
          setTotal(res.count)
          setData(
            res.rows.map((item) => ({
              ...item,
              key: item.id
            }))
          )
          setLoading(false)
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false))
    },
    [pageNumber, pageSize, search]
  )

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const toggleModal = (v) => {
    setVisible(v)
  }

  const toggleVisibleSlideModal = (v) => {
    setVisibleSlideModal(v)
  }

  const viewContent = async (item) => {
    toggleModal(false)
    setSelected(item)
    slideView(item)
  }

  const slideView = async (item) => {
    toggleVisibleSlideModal(true)
    setSelected(item)
  }

  const onButtonClick = (selected, type) => {
    if (type === 'ADD') {
      setSelected(null)
      form.resetFields()
      setModalType('ADD')
      toggleModal(true)
    }
    if (type === 'DELETE') {
      CurriculumUnitAPI.deleteCurriculumUnit({ id: selected.id })
        .then((res) => {
          notification['success']({
            message: 'Success',
            description: 'Delete curriculum unit successfully'
          })
          fetchData()
        })
        .catch((err) =>
          notification['error']({
            message: 'Error',
            description: err.message
          })
        )
    }
    if (type === 'EDIT') {
      form.resetFields()
      setModalType('EDIT')
      setSelected(selected)
      form.setFieldsValue(selected)
      toggleModal(true)
    }
  }

  const createCurriculumUnit = async (values) => {
    CurriculumUnitAPI.createCurriculumUnit({ ...values })
      .then(async (res) => {
        notification['success']({
          message: 'Success',
          description: 'Create curriculum unit successfully'
        })
        if (values.materials && values.materials.length > 0) {
          for (let i = 0; i < values.materials.length; i++) {
            const { id, name, mimeType } = values.materials[i].response.data
            CurriculumMaterialAPI.createCurriculumMaterial({
              curriculum_unit_id: res.id,
              content: id,
              order: i,
              type: mimeType || 'unknown',
              name: name
            }).then((m) => {
              notification['success']({
                message: 'Success',
                description: 'Create curriculum unit material successfully'
              })
            })
          }
        }
        fetchData()
        toggleModal(false)
      })
      .catch((err) => {
        notification['error']({
          message: 'Error',
          description: err.message
        })
      })
  }

  const updateCurriculumUnit = (values) => {
    CurriculumUnitAPI.updateCurriculumUnit({ ...values, id: selected.id })
      .then(async (res) => {
        notification['success']({
          message: 'Success',
          description: 'Update curriculum unit successfully'
        })
        if (values.materials && values.materials.length > 0) {
          for (let i = 0; i < values.materials.length; i++) {
            const { id, mimeType, name } = values.materials[i].response.data
            CurriculumMaterialAPI.createCurriculumMaterial({
              curriculum_unit_id: res.id,
              content: id,
              order: i,
              type: mimeType || 'unknown',
              name: name
            }).then((m) => {
              notification['success']({
                message: 'Success',
                description: 'Create curriculum unit material successfully'
              })
            })
          }
        }
        fetchData()
        toggleModal(false)
      })
      .catch((err) => {
        notification['error']({
          message: 'Error',
          description: err.message
        })
      })
  }

  const deleteMaterial = useCallback(
    (id) => {
      CurriculumMaterialAPI.deleteCurriculumMaterial({ id })
        .then((res) => {
          notification['success']({
            message: 'Success',
            description: 'Delete curriculum unit material successfully'
          })
          fetchData()
          _.remove(selected.curriculums_materials, (item) => item.id === id)
        })
        .catch((err) =>
          notification['error']({
            message: 'Error',
            description: err.message
          })
        )
    },
    [fetchData, selected]
  )
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e
    }

    return e?.fileList
  }

  const handleChangePagination = useCallback(
    (page_number, page_size) => {
      setPageNumber(page_number)
      setPageSize(page_size)
      fetchData({
        page_number,
        page_size,
        search: search
      })
    },
    [fetchData, search]
  )

  const handleSearchString = useCallback(
    (value) => {
      setSearch(value)
      fetchData({
        search: value,
        page_number: pageNumber,
        page_size: pageSize
      })
    },
    [fetchData, pageNumber, pageSize]
  )

  const columns = [
    {
      title: 'STT',
      dataIndex: 'id',
      key: 'id',
      width: '5%',
      render: (text, record, index) => index + 1
    },
    {
      title: 'Tên',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      render: (text, record) => text
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description',
      width: '20%',
      render: (text, record) => text
    },
    {
      title: 'Giáo trình',
      dataIndex: 'curriculum',
      key: 'curriculum',
      width: '20%',
      render: (text, record) => text?.name
    },
    {
      title: 'Học liệu',
      dataIndex: 'curriculums_materials',
      key: 'curriculums_materials',
      width: '20%',
      render: (text, record) => (
        <>
          {text?.map((item, index) => (
            <div>
              <a rel="noreferrer" onClick={() => slideView(item)}>
                {item.name}
              </a>
              <br />
            </div>
          ))}
        </>
      )
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: '20%',
      align: 'center',
      render: (value, record) => {
        return (
          <Space>
            <Button
              type="primary"
              size="small"
              onClick={() => onButtonClick(record, 'EDIT')}
            >
              Sửa
            </Button>
            <Popconfirm
              title="Chắc chắn xoá?"
              onConfirm={() => onButtonClick(record, 'DELETE')}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" danger size="small">
                Xoá
              </Button>
            </Popconfirm>
          </Space>
        )
      }
    }
  ]
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
  }
  const fetchCurriculums = useCallback(async (search) => {
    const res = await CurriculumAPI.getCurriculums({
      page_number: 1,
      page_size: 100,
      search
    })
    return res.rows.map((i) => ({
      label: i.name,
      value: i.id
    }))
  }, [])
  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Danh sách bài giảng"
              extra={
                <Space size="small">
                  <Search
                    placeholder="Nhập để tìm"
                    onSearch={handleSearchString}
                    enterButton="Tìm"
                    allowClear
                  />
                  <Button
                    type="primary"
                    onClick={() => onButtonClick(null, 'ADD')}
                    title="Add new curriculum unit"
                  >
                    Thêm mới
                  </Button>
                </Space>
              }
            >
              <div className="table-responsive">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={data}
                  className="ant-border-space"
                  pagination={{
                    position: ['topCenter', 'bottomCenter'],
                    total,
                    pageSize,
                    current: pageNumber,
                    onChange: handleChangePagination,
                    showSizeChanger: true,
                    onShowSizeChange: handleChangePagination,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`
                  }}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <Modal
        title={
          modalType === 'ADD'
            ? 'Thêm bài giảng'
            : `Sửa bài giảng "${selected?.name}"`
        }
        open={visible}
        onCancel={() => toggleModal(false)}
        onOk={() => form.submit()}
        width={1000}
      >
        <Form
          form={form}
          {...layout}
          onFinish={
            modalType === 'ADD' ? createCurriculumUnit : updateCurriculumUnit
          }
        >
          <Form.Item
            label="Tên"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please input name'
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Mô tả" name="description">
            <Input />
          </Form.Item>
          <Form.Item
            label="Giáo trình môn học"
            name="curriculum_id"
            rules={[
              {
                required: true,
                message: 'Please input curriculum'
              }
            ]}
          >
            <DebounceSelect
              placeholder="Select curriculum"
              fetchOptions={fetchCurriculums}
              allowClear
            />
          </Form.Item>
          {modalType === 'EDIT' && (
            <Form.Item label="Học liệu">
              <List
                style={{ overflow: 'auto', maxHeight: 200 }}
                dataSource={selected?.curriculums_materials}
                renderItem={(item) => (
                  <List.Item
                    actions={[
                      <Popconfirm
                        title="Chắc chắn xoá?"
                        onConfirm={() => deleteMaterial(item.id)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="primary" danger size="small">
                          Xoá
                        </Button>
                      </Popconfirm>,
                      <Button
                        type="primary"
                        onClick={() => viewContent(item)}
                      >
                        Xem
                      </Button>
                    ]}
                  >
                    <span>FILE - {item.name}</span>
                  </List.Item>
                )}
                bordered
              />
            </Form.Item>
          )}
          <Form.Item
            label="Tải lên học liệu"
            name="materials"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            help={
              <span
                style={{
                  fontSize: '12px',
                  fontStyle: 'italic',
                  color: 'red'
                }}
              >
                File size limit 50MB. Allowed file types{' '}
                {preview_extension.join(',')}
              </span>
            }
          >
            <Upload
              listType="picture"
              accept={preview_extension.join(',')}
              maxCount={10}
              multiple={true}
              action={(file) => {
                return UploadAPI.getUploadParams()?.url
              }}
              headers={{
                Authorization: UploadAPI.getUploadParams()?.token
              }}
              onChange={(info) => {
                if (info.file.status !== 'uploading') {
                  console.log(info.file, info.fileList)
                }

                if (info.file.status === 'done') {
                  message.success(
                    `${info.file.name} file uploaded successfully`
                  )
                } else if (info.file.status === 'error') {
                  message.error(`${info.file.name} file upload failed.`)
                }
              }}
              // onRemove={(file) => {
              //   UploadAPI.deleteUploadFile(file.response?.data?.url)
              // }}
              progress={{
                strokeColor: {
                  '0%': '#108ee9',
                  '100%': '#87d068'
                },
                strokeWidth: 3,
                format: (percent) =>
                  percent && `${parseFloat(percent.toFixed(2))}%`
              }}
            >
              <Space direction="horizontal" size={16}>
                <Button icon={<UploadOutlined />}>Upload</Button>
              </Space>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
      <MaterialViewModal
        open={visibleSlideModal}
        onCancel={() => toggleVisibleSlideModal(false)}
        material={selected}
      />
    </>
  )
}

export default CurriculumUnit
