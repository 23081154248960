import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  List,
  Modal,
  Popconfirm,
  Row,
  Space,
  Table,
  Tabs,
  notification
} from 'antd'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ClassAPI from '../../api/ClassAPI'
import CurriculumAPI from '../../api/CurriculumAPI'
import TeacherAPI from '../../api/TeacherAPI'
import CurriculumGradeTable from '../../components/CurriculumGradeTable'
import ImportExport from '../../components/ImportExport'
import MaterialViewModal from '../../components/MaterialViewModal'

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
}

function Curriculum() {
  const [formStudent] = Form.useForm()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [unitModalOpen, setUnitModalOpen] = useState(false)
  const [modalType, setModalType] = useState('ADD')
  const [selected, setSelected] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(0)
  const [search, setSearch] = useState('')
  const { id } = useParams()
  const [students, setStudents] = useState([])
  const [tabType, setTabType] = useState('curriculums')
  const [visibleSlideModal, setVisibleSlideModal] = useState(false)
  const [studentModalOpen, setStudentModalOpen] = useState(false)
  const [classInfo, setClassInfo] = useState(null)
  const [showImportExport, setShowImportExport] = useState(false)

  const toggleImportExport = () => {
    setShowImportExport(!showImportExport)
    fetchData()
    fetchStudent()
  }

  const toggleVisibleSlideModal = (v) => {
    setVisibleSlideModal(v)
  }

  const slideView = async (item) => {
    toggleVisibleSlideModal(true)
  }

  const viewContent = async (item) => {
    toggleUnitModal(false)
    setSelected(item)
    slideView(item)
  }

  const fetchData = useCallback(
    async (query) => {
      setLoading(true)
      CurriculumAPI.teacherGetCurriculums({
        page_number: query?.page_number || pageNumber,
        page_size: query?.page_size || pageSize,
        search: query?.search || search,
        class_id: id
      })
        .then((res) => {
          setTotal(res.count)
          setData(
            res.rows.map((item) => ({
              ...item,
              key: item.id
            }))
          )
          setLoading(false)
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false))
    },
    [pageNumber, pageSize, search, id]
  )

  const fetchStudent = useCallback(
    async (query) => {
      setLoading(true)
      TeacherAPI.teacherGetStudents({
        page_number: query?.page_number || pageNumber,
        page_size: query?.page_size || pageSize,
        search: query?.search || search,
        class_id: id
      })
        .then((res) => {
          setTotal(res.count)
          setStudents(
            res.rows.map((item) => ({
              ...item,
              key: item.id
            }))
          )
          setLoading(false)
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false))
    },
    [pageNumber, pageSize, search, id]
  )
  const createStudent = (values) => {
    TeacherAPI.teacherCreateStudent({
      ...values,
      username: values.email,
      class_id: id,
      school_id: classInfo?.school_id,
      password: '123456',
      rePassword: '123456'
    })
      .then((res) => {
        notification['success']({
          message: 'Success',
          description: 'Create student successfully'
        })
        toggleStudentModal(false)
        fetchStudent()
      })
      .catch((err) => {
        notification['error']({
          message: 'Error',
          description: err.message
        })
      })
  }

  const updateStudent = (values) => {
    TeacherAPI.teacherUpdateStudent({
      ...values,
      username: values.email,
      class_id: id,
      id: selected.id,
      user_id: selected.user_id
    })
      .then((res) => {
        notification['success']({
          message: 'Success',
          description: 'Update student successfully'
        })
        fetchStudent()
        toggleStudentModal(false)
      })
      .catch((err) => {
        notification['error']({
          message: 'Error',
          description: err.message
        })
      })
  }

  const onButtonClick = (selected, type) => {
    if (type === 'ADD') {
      formStudent.resetFields()
      setModalType('ADD')
      toggleStudentModal(true)
    }
    if (type === 'DELETE') {
      TeacherAPI.teacherDeleteStudent({ id: selected.id })
        .then((res) => {
          notification['success']({
            message: 'Success',
            description: 'Delete student successfully'
          })
          fetchStudent()
        })
        .catch((err) =>
          notification['error']({
            message: 'Error',
            description: err.message
          })
        )
    }
    if (type === 'EDIT') {
      formStudent.resetFields()
      setModalType('EDIT')
      selected.date_of_birth = moment(selected.date_of_birth)
      selected.username = selected.user?.username
      selected.email = selected.user?.email
      setSelected(selected)
      formStudent.setFieldsValue(selected)
      toggleStudentModal(true)
    }
    if (type === 'IMPORT') {
      setModalType('IMPORT_STUDENT')
      toggleImportExport()
    }

    if (type === 'EXPORT') {
      setModalType('EXPORT_STUDENT')
      toggleImportExport()
    }
  }

  const onChangeTab = (key) => {
    setTabType(key)
    setPageNumber(1)
    setPageSize(10)
    setSearch('')
  }

  const toggleUnitModal = (v) => {
    setUnitModalOpen(v)
  }

  const toggleStudentModal = (v) => {
    setStudentModalOpen(v)
  }

  const fetchClassInfo = useCallback(
    async (query) => {
      setLoading(true)
      ClassAPI.teacherGetClass({ id })
        .then((res) => {
          setClassInfo(res)
          setLoading(false)
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false))
    },
    [id]
  )

  useEffect(() => {
    fetchClassInfo()
    if (tabType === 'curriculums') {
      fetchData()
    } else {
      fetchStudent()
    }
  }, [fetchClassInfo, fetchData, fetchStudent, tabType])
  const handleChangePagination = useCallback(
    (page_number, page_size) => {
      setPageNumber(page_number)
      setPageSize(page_size)
      fetchData({
        page_number,
        page_size,
        search: search
      })
    },
    [fetchData, search]
  )

  const studentColumns = [
    {
      title: 'STT',
      dataIndex: 'id',
      key: 'id',
      width: '5%',
      render: (text, record, index) => index + 1
    },
    {
      title: 'Email',
      dataIndex: 'user',
      key: 'user',
      width: '32%',
      render: (text, record) => text?.email
    },
    {
      title: 'Tên',
      dataIndex: 'full_name',
      key: 'full_name',
      width: '32%',
      render: (text, record) => text
    },
    {
      title: 'Ngày sinh',
      dataIndex: 'date_of_birth',
      key: 'date_of_birth',
      width: '32%',
      render: (text, record) => moment(text).format('DD/MM/YYYY')
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'address',
      key: 'address',
      width: '32%',
      render: (text, record) => text
    },
    {
      title: 'SĐT',
      dataIndex: 'phone',
      key: 'phone',
      width: '32%',
      render: (text, record) => text
    },
    {
      title: 'Trường',
      dataIndex: 'school',
      key: 'school',
      width: '20%',
      render: (text, record) => text?.name
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: '20%',
      align: 'center',
      render: (value, record) => {
        return (
          <Space>
            <Button
              type="primary"
              size="small"
              onClick={() => onButtonClick(record, 'EDIT')}
            >
              Sửa
            </Button>
            <Popconfirm
              title="Chắc chắn xoá?"
              onConfirm={() => onButtonClick(record, 'DELETE')}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" danger size="small">
                Xoá
              </Button>
            </Popconfirm>
          </Space>
        )
      }
    }
  ]
  const unitColumns = [
    {
      title: 'STT',
      dataIndex: 'id',
      key: 'id',
      width: '5%',
      render: (text, record, index) => index + 1
    },
    {
      title: 'Tên',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      render: (text, record) => text
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description',
      width: '10%',
      render: (text, record) => text
    },
    {
      title: 'Học liệu',
      dataIndex: 'curriculums_materials',
      key: 'curriculums_materials',
      width: '50%',
      render: (text, record) => (
        <List
          size="small"
          dataSource={text}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Button type="primary" onClick={() => viewContent(item)}>
                  Xem
                </Button>
              ]}
            >
              {item.name}
            </List.Item>
          )}
        />
      )
    }
  ]
  const curriculumColumns = [
    {
      title: 'STT',
      dataIndex: 'id',
      key: 'id',
      width: '5%',
      render: (text, record, index) => index + 1
    },
    {
      title: 'Tên',
      dataIndex: 'curriculum',
      key: 'curriculum',
      width: '20%',
      render: (text, record) => text?.name
    },
    {
      title: 'Mô tả',
      dataIndex: 'curriculum',
      key: 'curriculum',
      width: '20%',
      render: (text, record) => text?.description
    },
    {
      title: 'Lớp',
      dataIndex: 'class',
      key: 'class',
      width: '20%',
      render: (text, record) => text?.name
    },
    {
      title: 'Giáo viên',
      dataIndex: 'class',
      key: 'class',
      width: '20%',
      render: (text, record) =>
        text?.teachers?.map((item) => item.full_name).join(', ')
    },
    {
      title: 'Bài giảng',
      dataIndex: 'curriculum',
      key: 'curriculum',
      width: '20%',
      render: (text, record) => (
        <Button
          type="primary"
          size="small"
          onClick={() => {
            setSelected(record)
            toggleUnitModal(true)
          }}
        >
          {text?.curriculums_units?.length + ' bài giảng'}
        </Button>
      )
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: '20%',
      align: 'center',
      render: (value, record) => {
        return (
          <Space>
            <Button
              type="primary"
              size="small"
              onClick={() => {
                setSelected(record)
                setGradeModalOpen(true)
              }}
            >
              Bảng điểm
            </Button>
          </Space>
        )
      }
    }
  ]

  const [gradeModalOpen, setGradeModalOpen] = useState(false)

  return (
    <>
      <Tabs type="card" size="large" onChange={onChangeTab} activeKey={tabType}>
        <Tabs.TabPane key="curriculums" tab="Giáo trình môn học">
          <div className="tabled">
            <Row gutter={[24, 0]}>
              <Col xs="24" xl={24}>
                <Card
                  bordered={false}
                  className="criclebox tablespace mb-24"
                  title="Danh sách giáo trình"
                  extra={null}
                >
                  <div className="table-responsive">
                    <Table
                      loading={loading}
                      columns={curriculumColumns}
                      dataSource={data}
                      rowKey='curriculum_id'
                      className="ant-border-space"
                      pagination={{
                        position: ['topCenter', 'bottomCenter'],
                        total,
                        pageSize,
                        current: pageNumber,
                        onChange: handleChangePagination,
                        showSizeChanger: true,
                        onShowSizeChange: handleChangePagination,
                        showTotal: (total, range) =>
                          `${range[0]}-${range[1]} of ${total} items`
                      }}
                    />
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane key="students" tab="Học sinh">
          <div className="tabled">
            <Row gutter={[24, 0]}>
              <Col xs="24" xl={24}>
                <Card
                  bordered={false}
                  className="criclebox tablespace mb-24"
                  title="Danh sách học sinh"
                  extra={
                    <Space size="small">
                      {/* <Search
                        placeholder="Enter string to search"
                        onSearch={handleSearchString}
                        enterButton="Search"
                        allowClear
                      /> */}
                      <Button
                        type="primary"
                        onClick={() => onButtonClick(null, 'ADD')}
                        title="Thêm học sinh"
                      >
                        Thêm học sinh
                      </Button>
                      <Button
                        type="primary"
                        onClick={() => onButtonClick(null, 'EXPORT')}
                        title="Export"
                      >
                        Export
                      </Button>
                      <Button
                        type="primary"
                        onClick={() => onButtonClick(null, 'IMPORT')}
                        title="Import"
                      >
                        Import
                      </Button>
                    </Space>
                  }
                >
                  <div className="table-responsive">
                    <Table
                      loading={loading}
                      columns={studentColumns}
                      dataSource={students}
                      className="ant-border-space"
                      pagination={{
                        position: ['topCenter', 'bottomCenter'],
                        total,
                        pageSize,
                        current: pageNumber,
                        onChange: handleChangePagination,
                        showSizeChanger: true,
                        onShowSizeChange: handleChangePagination,
                        showTotal: (total, range) =>
                          `${range[0]}-${range[1]} of ${total} items`
                      }}
                    />
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </Tabs.TabPane>
      </Tabs>
      <Modal
        title="Bài giảng"
        open={unitModalOpen}
        cancelText="Đóng"
        onCancel={() => toggleUnitModal(false)}
        okButtonProps={{ hidden: true }}
        width={'100%'}
      >
        <Table
          scroll={{ y: 500, x: 1000 }}
          dataSource={selected?.curriculum?.curriculums_units}
          columns={unitColumns}
          className="ant-border-space"
          pagination={false}
        />
      </Modal>
      <MaterialViewModal
        open={visibleSlideModal}
        onCancel={() => toggleVisibleSlideModal(false)}
        material={selected}
      />
      <Modal
        title={
          modalType === 'ADD'
            ? 'Create new student for class ' + classInfo?.name
            : `Editing student "${selected?.full_name}"`
        }
        open={studentModalOpen}
        onCancel={() => toggleStudentModal(false)}
        onOk={() => formStudent.submit()}
      >
        <Form
          form={formStudent}
          {...layout}
          onFinish={modalType === 'ADD' ? createStudent : updateStudent}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: 'Please input email'
              }
            ]}
          >
            <Input disabled={modalType === 'EDIT'} />
          </Form.Item>
          <Form.Item
            label="Full Name"
            name="full_name"
            rules={[
              {
                required: true,
                message: 'Please input full name'
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Date of birth"
            name="date_of_birth"
            rules={[
              {
                required: true,
                message: 'Please input date of birth'
              }
            ]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item label="Address" name="address">
            <Input />
          </Form.Item>
          <Form.Item label="Phone" name="phone">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <ImportExport
        open={showImportExport}
        close={toggleImportExport}
        type={modalType}
        role="teacher"
        class_id={id}
      />
      <Modal
        open={gradeModalOpen}
        title={`Bảng điểm ${selected?.class?.name} - ${selected?.curriculum?.name}`}
        cancelText="Đóng"
        onCancel={() => setGradeModalOpen(false)}
        okButtonProps={{ hidden: true }}
        width={800}
        destroyOnClose
        maskClosable={false}
      >
        {
          selected?.curriculum_id && (
            <CurriculumGradeTable classId={id} curriculumId={selected.curriculum_id} role='teacher' />
          )
        }
      </Modal>
    </>
  )
}

export default Curriculum
