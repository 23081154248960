import { useState, useEffect } from 'react'

import { Row, Col, Breadcrumb, Input } from 'antd'

import { NavLink, Link, Redirect } from 'react-router-dom'
import { SearchOutlined, UserOutlined, LogoutOutlined } from '@ant-design/icons'
import {
  useAuthState,
  doLogout,
  useAuthDispatch
} from '../../context/AuthContext'
import jwtDecode from 'jwt-decode'

function Header({ name, subName }) {
  const dispatch = useAuthDispatch()
  useEffect(() => window.scrollTo(0, 0))
  const { username, remember, token } = useAuthState()
  const { school_name } = token ? jwtDecode(token) : { school_name: null }
  return (
    <>
      <Row gutter={[24, 0]}>
        <Col span={24} md={6}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to="/"><b style={{ fontSize: 14 }}>{school_name ? school_name : 'Administration'}</b></NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item style={{ textTransform: 'capitalize' }}>
              {name.substring(name.lastIndexOf('/')).replace('/', '')}
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="ant-page-header-heading">
            <span
              className="ant-page-header-heading-title"
              style={{ textTransform: 'capitalize' }}
            >
              {subName.substring(name.lastIndexOf('/')).replace('/', '')}
            </span>
          </div>
        </Col>
        <Col span={24} md={18} className="header-control">
          {username ? (
            <>
              <a className="btn-sign-in" onClick={() => doLogout(dispatch)}>
                <LogoutOutlined />
                <span>Đăng xuất</span>
              </a>
              <Link to="/profile" className="btn-sign-in">
                <UserOutlined />
                <span>{school_name ? username + ' - ' + school_name : username}</span>
              </Link>
            </>
          ) : (
            <Link to="/login" className="btn-sign-in">
              <UserOutlined />
              <span>Đăng nhập</span>
            </Link>
          )}

          {/* <Input
            className="header-search"
            placeholder="Type here..."
            prefix={<SearchOutlined />}
          /> */}
        </Col>
      </Row>
    </>
  )
}

export default Header
