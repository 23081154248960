import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { notification, Avatar, Upload, Tooltip } from 'antd'
import ImgCrop from 'antd-img-crop'
import React, { useEffect, useState } from 'react'
import UploadAPI from '../../api/UploadAPI'
import UserAPI from '../../api/UserAPI'

const ROOT = process.env.REACT_APP_LMS_SERVICE_URL

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'

  if (!isJpgOrPng) {
    notification['error']({
      message: 'Error',
      description: 'You can only upload JPG/PNG file!'
    })
  }

  const isLt2M = file.size / 1024 / 1024 < 2

  if (!isLt2M) {
    notification['error']({
      message: 'Error',
      description: 'Image must smaller than 2MB!'
    })
  }

  return isJpgOrPng && isLt2M
}

const AvatarCropUpload = ({ info }) => {
  const [loading, setLoading] = useState(false)
  const [avatarUrl, setAvatarUrl] = useState(info?.avatar)

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8
        }}
      >
        Upload
      </div>
    </div>
  )

  const onUploadImage = async (file) => {
    if (file) {
      try {
        setLoading(true)
        const response = await UploadAPI.uploadAvatar(file)
        await UserAPI.updateProfile({ avatar: response.url }, info?.id)
        setAvatarUrl(response.url)
           notification['success']({
              message: 'Success',
              description: 'Update avatar successfully'
            })
      } catch (error) {
        notification['error']({
          message: 'Error',
          description: error
        })
      } finally {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    if (info) setAvatarUrl(info.avatar)
  }, [info])
  return (
    <ImgCrop onModalOk={onUploadImage} modalOk="Upload">
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        beforeUpload={beforeUpload}
      >
        {avatarUrl ? (
          <Tooltip color='#1890ff' placement="bottom" title="Click to change avatar">
            <Avatar size={74} shape="square" src={ROOT + avatarUrl} />
          </Tooltip>
        ) : (
          uploadButton
        )}
      </Upload>
    </ImgCrop>
  )
}
export default AvatarCropUpload
