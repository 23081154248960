import axios from 'axios'
import _ from 'lodash'
import * as store from '../utils/storage'
import { notification } from 'antd'
const queryString = require('query-string')

axios.defaults.timeout = 120000
axios.defaults.headers.common.Accept = 'application/json'

const ROUTE_ALIAS = {
  '/core/': process.env.REACT_APP_LMS_SERVICE_URL
}

const getApiRoot = (route) => {
  let API_ROOT = ''
  _.forIn(ROUTE_ALIAS, function (value, key) {
    if (route.indexOf(key) !== -1) API_ROOT = value + route.replace(key, '/')
  })

  return API_ROOT
}

export default class ApiSender {
  static handleResponse(res) {
    if (res.status === 200) {
      if (res.data.status && res.data.status !== 200) {
        throw new Error(res.data.reason)
      }
      return res.data.data
    }
    throw new Error(res.data.reason || res.data)
  }
  static handleError(error) {
    if (error.response) {
      notification.error({
        message: 'Error',
        description: error.response.data.reason || error.response.data
      })
      throw new Error(error)
    } else {
      notification.error({
        message: 'Error',
        description: error.message
      })
      throw new Error(error.message)
    }
  }
  static async get(url, params = {}) {
    const API_ROOT = getApiRoot(url)
    const AUTH_TOKEN = store.get('token') || ''
    axios.defaults.headers.common.authorization = AUTH_TOKEN
    // axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

    return axios
      .get(API_ROOT, {
        params,
        paramsSerializer(_params) {
          return queryString.stringify(_params, {
            skipNull: true,
            skipEmptyString: true
          })
        }
      })
      .then(this.handleResponse)
      .catch(this.handleError)
  }
  static post(url, data = {}) {
    const API_ROOT = getApiRoot(url)
    const AUTH_TOKEN = store.get('token') || ''
    axios.defaults.headers.common.authorization = AUTH_TOKEN

    return axios
      .post(API_ROOT, data)
      .then(this.handleResponse)
      .catch(this.handleError)
  }
  static put(url, data = {}) {
    const API_ROOT = getApiRoot(url)
    const AUTH_TOKEN = store.get('token') || ''
    axios.defaults.headers.common.authorization = AUTH_TOKEN

    return axios
      .put(API_ROOT, data)
      .then(this.handleResponse)
      .catch(this.handleError)
  }
  static patch(url, data = {}) {
    const API_ROOT = getApiRoot(url)
    const AUTH_TOKEN = store.get('token') || ''
    axios.defaults.headers.common.authorization = AUTH_TOKEN

    return axios
      .patch(API_ROOT, data)
      .then(this.handleResponse)
      .catch(this.handleError)
  }
  static delete(url) {
    const API_ROOT = getApiRoot(url)
    const AUTH_TOKEN = store.get('token') || ''
    axios.defaults.headers.common.authorization = AUTH_TOKEN

    return axios
      .delete(API_ROOT)
      .then(this.handleResponse)
      .catch(this.handleError)
  }
  static async download(url, params = {}) {
    const API_ROOT = getApiRoot(url)
    const AUTH_TOKEN = store.get('token') || ''
    axios.defaults.headers.common.authorization = AUTH_TOKEN
    //download and save file
    const response = await axios({
      url: API_ROOT,
      method: 'POST',
      responseType: 'blob',
      data: params
    })
    const url_ = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url_;
    link.setAttribute('download', params.export_type === 'student' ? Date.now() + 'clevertech_export_students.xlsx' : Date.now() + 'clevertech_export_teachers.xlsx');
    document.body.appendChild(link);
    link.click();
    return;
  }
  static getApiRoot = getApiRoot
}
