import { formatDate as fullFormat } from '@fullcalendar/react'
export const getEpochTime = (time) => {
  const myDate = new Date(time)
  return myDate.getTime()
}
export const formatDate = (date) => {
  return fullFormat(date, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  })
}

/*
 * @param {Date} start
 * @param {Date} end
 * @param {Number} flextime in minutes (default 30)
 * @return {Boolean}
 */
export const invalidTime = (time, flextime = 30) => {
  const current = getEpochTime(new Date())
  const startMoment = getEpochTime(time) - flextime * 60000
  const endMoment = getEpochTime(time) + flextime * 60000
  if (startMoment < current && current < endMoment) {
    return false
  }
  return true
}
/*
 * convert time to local time
 * @param {Date} time
 * @return {Date}
 */
export const convertToLocalTime = (time) => {
  const localTime = new Date(time)
  const offset = localTime.getTimezoneOffset() * 60000
  return new Date(localTime.getTime() - offset)
}
