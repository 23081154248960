/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Row,
  Col,
  Card,
  Table,
  Space,
  Button,
  notification,
  Tag,
  Modal,
  Form,
  Input,
  Popconfirm
} from 'antd'

import { useCallback, useEffect, useState } from 'react'
import CurriculumAPI from '../../api/CurriculumAPI'
import { ItemMultipleSelect } from '../../components/MultipleSelect'
import ClassAPI from '../../api/ClassAPI'
import _ from 'lodash'
import MaterialViewModal from '../../components/MaterialViewModal'

const { Search } = Input

function Curriculum() {
  const [form] = Form.useForm()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [modalType, setModalType] = useState('ADD')
  const [selected, setSelected] = useState(null)
  const [selectedMat, setSelectedMat] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(0)
  const [search, setSearch] = useState('')
  const [visibleUnitsModal, setVisibleUnitsModal] = useState(false)
  const [visibleSlideModal, setVisibleSlideModal] = useState(false)

  const toggleVisibleSlideModal = (v) => {
    setVisibleSlideModal(v)
  }

  const fetchData = useCallback(
    async (query) => {
      setLoading(true)
      CurriculumAPI.getCurriculums({
        page_number: query?.page_number || pageNumber,
        page_size: query?.page_size || pageSize,
        search: query?.search || search
      })
        .then((res) => {
          setTotal(res.count)
          setData(
            res.rows.map((item) => ({
              ...item,
              key: item.id
            }))
          )
          setLoading(false)
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false))
    },
    [pageNumber, pageSize, search]
  )

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const toggleModal = (v) => {
    setVisible(v)
  }
  const toggleUnitsModal = (v) => {
    setVisibleUnitsModal(v)
  }

  const onButtonClick = (selected, type) => {
    if (type === 'ADD') {
      setSelected(null)
      form.resetFields()
      setModalType('ADD')
      toggleModal(true)
    }
    if (type === 'DELETE') {
      CurriculumAPI.deleteCurriculum({ id: selected.id })
        .then((res) => {
          notification['success']({
            message: 'Success',
            description: 'Delete curriculum successfully'
          })
          fetchData()
        })
        .catch((err) =>
          notification['error']({
            message: 'Error',
            description: err.message
          })
        )
    }
    if (type === 'EDIT') {
      form.resetFields()
      setModalType('EDIT')
      setSelected(selected)
      form.setFieldsValue({
        class_id: selected?.classes?.map((item) => item.id)
      })
      form.setFieldsValue(selected)
      toggleModal(true)
    }
  }

  const createCurriculum = async (values) => {
    CurriculumAPI.createCurriculum({ ...values })
      .then(async (res) => {
        notification['success']({
          message: 'Success',
          description: 'Create curriculum successfully'
        })
        fetchData()
        toggleModal(false)
      })
      .catch((err) => {
        notification['error']({
          message: 'Error',
          description: err.message
        })
      })
  }

  const updateCurriculum = (values) => {
    console.log(values)
    CurriculumAPI.updateCurriculum({ ...values, id: selected.id })
      .then(async (res) => {
        notification['success']({
          message: 'Success',
          description: 'Update curriculum successfully'
        })
        fetchData()
        toggleModal(false)
      })
      .catch((err) => {
        notification['error']({
          message: 'Error',
          description: err.message
        })
      })
  }
  const handleChangePagination = useCallback(
    (page_number, page_size) => {
      setPageNumber(page_number)
      setPageSize(page_size)
      fetchData({
        page_number,
        page_size,
        search: search
      })
    },
    [fetchData, search]
  )

  const handleSearchString = useCallback(
    (value) => {
      setSearch(value)
      fetchData({
        search: value,
        page_number: pageNumber,
        page_size: pageSize
      })
    },
    [fetchData, pageNumber, pageSize]
  )

  const unitColumns = [
    {
      title: 'STT',
      dataIndex: 'id',
      key: 'id',
      width: '5%',
      render: (text, record, index) => index + 1
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      render: (text, record) => text
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '20%',
      render: (text, record) => text
    },
    {
      title: 'Materials',
      dataIndex: 'curriculums_materials',
      key: 'curriculums_materials',
      width: '50%',
      render: (text, record) => (
        <div>
          {text?.map((item, index) => (
            <div>
              <Tag
                color="blue"
                key={index}
                style={{ cursor: 'pointer' }}
                title="Click to preview material"
                onClick={() => {
                  setSelectedMat(item)
                  toggleVisibleSlideModal(true)
                }}
              >
                {item.name}
              </Tag>
            </div>
          ))}
        </div>
      )
    }
  ]

  const columns = [
    {
      title: 'STT',
      dataIndex: 'id',
      key: 'id',
      width: '5%',
      render: (text, record, index) => index + 1
    },
    {
      title: 'Tên',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      render: (text, record) => text
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description',
      width: '20%',
      render: (text, record) => text
    },
    {
      title: 'Lớp',
      dataIndex: 'classes',
      key: 'classes',
      width: '20%',
      render: (text, record) =>
        text?.map((item) => (
          <>
            <Tag color="blue" key={item.id}>
              {item.name + ' - ' + item.school?.name}
            </Tag>
            <br />
          </>
        ))
    },
    {
      title: 'Bài giảng',
      dataIndex: 'curriculums_units',
      key: 'curriculums_units',
      width: '20%',
      render: (text, record) => (
        <Button
          type="primary"
          size="small"
          onClick={() => {
            setSelected(record)
            toggleUnitsModal(true)
          }}
        >
          {text?.length + ' bài giảng'}
        </Button>
      )
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: '20%',
      align: 'center',
      render: (value, record) => {
        return (
          <Space>
            <Button
              type="primary"
              size="small"
              onClick={() => onButtonClick(record, 'EDIT')}
            >
              Sửa
            </Button>
            <Popconfirm
              title="Chắc chắn xoá?"
              onConfirm={() => onButtonClick(record, 'DELETE')}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" danger size="small">
                Xoá
              </Button>
            </Popconfirm>
          </Space>
        )
      }
    }
  ]
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
  }
  const fetchClasses = useCallback(async (search) => {
    const res = await ClassAPI.getClasses({
      page_number: 1,
      page_size: 100,
      search
    })
    return res.rows.map((i) => ({
      label: i.name + ' - ' + i.school?.name,
      value: i.id
    }))
  }, [])
  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Danh sách giáo trình"
              extra={
                <Space size="small">
                  <Search
                    placeholder="Nhập để tìm"
                    onSearch={handleSearchString}
                    enterButton="Tìm"
                    allowClear
                  />
                  <Button
                    type="primary"
                    onClick={() => onButtonClick(null, 'ADD')}
                    title="Add new curriculum"
                  >
                    Thêm mới
                  </Button>
                </Space>
              }
            >
              <div className="table-responsive">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={data}
                  className="ant-border-space"
                  pagination={{
                    position: ['topCenter', 'bottomCenter'],
                    total,
                    pageSize,
                    current: pageNumber,
                    onChange: handleChangePagination,
                    showSizeChanger: true,
                    onShowSizeChange: handleChangePagination,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`
                  }}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <Modal
        title={
          modalType === 'ADD'
            ? 'Create new curriculum'
            : `Editing curriculum "${selected?.name}"`
        }
        open={visible}
        onCancel={() => toggleModal(false)}
        onOk={() => form.submit()}
        width={1000}
      >
        <Form
          form={form}
          {...layout}
          onFinish={modalType === 'ADD' ? createCurriculum : updateCurriculum}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please input name'
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Description" name="description">
            <Input />
          </Form.Item>
          <ItemMultipleSelect
            label="Classes"
            name="class_id"
            fetchOptions={fetchClasses}
            placeholder="Select class"
            allowClear
          />
        </Form>
      </Modal>
      <Modal
        title="Curriculum Units"
        open={visibleUnitsModal}
        onCancel={() => toggleUnitsModal(false)}
        width={1000}
      >
        <Table
          scroll={{ y: 500, x: 1000 }}
          dataSource={selected?.curriculums_units}
          columns={unitColumns}
          className="ant-border-space"
          pagination={false}
        />
      </Modal>
      <MaterialViewModal
        open={visibleSlideModal}
        onCancel={() => toggleVisibleSlideModal(false)}
        material={selectedMat}
      />
    </>
  )
}

export default Curriculum
