import {
  Row,
  Col,
  Card,
  Table,
  Space,
  Button,
  notification,
  Tag,
  Modal,
  Form,
  Input,
  Divider,
  Popconfirm
} from 'antd'

import { useCallback, useEffect, useState } from 'react'
import SchoolAPI from '../../api/SchoolAPI'

const { Search } = Input

function Schools() {
  const [form] = Form.useForm()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [modalType, setModalType] = useState('ADD')
  const [selected, setSelected] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(0)
  const [search, setSearch] = useState('')

  const fetchData = useCallback(
    async (query) => {
      setLoading(true)
      SchoolAPI.getSchools({
        page_number: query?.page_number || pageNumber,
        page_size: query?.page_size || pageSize,
        search: query?.search || search
      })
        .then((res) => {
          setTotal(res.count)
          setData(
            res.rows.map((item) => ({
              ...item,
              key: item.id
            }))
          )
          setLoading(false)
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false))
    }, [pageNumber, pageSize, search]
  )

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const toggleModal = (v) => {
    setVisible(v)
  }

  const onButtonClick = (selected, type) => {
    if (type === 'ADD') {
      form.resetFields()
      setModalType('ADD')
      toggleModal(true)
    }
    if (type === 'DELETE') {
      SchoolAPI.deleteSchool({ id: selected.id })
        .then((res) => {
          notification['success']({
            message: 'Success',
            description: 'Delete school successfully'
          })
          fetchData()
        })
        .catch((err) =>
          notification['error']({
            message: 'Error',
            description: err.message
          })
        )
    }
    if (type === 'EDIT') {
      form.resetFields()
      setModalType('EDIT')
      setSelected(selected)
      form.setFieldsValue(selected)
      toggleModal(true)
    }
  }

  const createSchool = (values) => {
    SchoolAPI.createSchool({ ...values })
      .then((res) => {
        notification['success']({
          message: 'Success',
          description: 'Create school successfully'
        })
        fetchData()
        toggleModal(false)
      })
      .catch((err) => {
        notification['error']({
          message: 'Error',
          description: err.message
        })
      })
  }

  const updateSchool = (values) => {
    SchoolAPI.updateSchool({ ...values, id: selected.id })
      .then((res) => {
        notification['success']({
          message: 'Success',
          description: 'Update school successfully'
        })
        fetchData()
        toggleModal(false)
      })
      .catch((err) => {
        notification['error']({
          message: 'Error',
          description: err.message
        })
      })
  }

  const handleChangePagination = useCallback((page_number, page_size) => {
    setPageNumber(page_number)
    setPageSize(page_size)
    fetchData({
      page_number,
      page_size,
      search: search
    })
  }, [fetchData, search])

  const handleSearchString = useCallback((value) => {
    setSearch(value)
    fetchData({
      search: value,
      page_number: pageNumber,
      page_size: pageSize
    })
  }, [fetchData, pageNumber, pageSize])

  const columns = [
    {
      title: 'STT',
      dataIndex: 'id',
      key: 'id',
      width: '5%',
      render: (text, record, index) => index + 1
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '32%',
      render: (text, record) => text
    },
    {
      title: 'Short Name',
      dataIndex: 'short_name',
      key: 'short_name',
      width: '32%',
      render: (text, record) => text
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      width: '32%',
      render: (text, record) => text
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '32%',
      render: (text, record) => text
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      width: '20%',
      align: 'center',
      render: (value, record) => {
        return (
          <Space>
            <Button type='primary' size='small' onClick={() => onButtonClick(record, 'EDIT')}> Edit </Button>
            <Popconfirm
              title="Are you sure to delete this task?"
              onConfirm={() => onButtonClick(record, 'DELETE')}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="primary"
                danger
                size="small"
              >
                Delete
              </Button>
            </Popconfirm>
          </Space>
        )
      }
    }
  ]
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Schools table"
              extra={
                <Space size="small">
                  <Search placeholder='Enter string to search' onSearch={handleSearchString} enterButton='Search' allowClear />
                  <Button
                    type="primary"
                    onClick={() => onButtonClick(null, 'ADD')}
                    title="Add new school"
                  >
                    Create
                  </Button>
                </Space>
              }
            >
              <div className="table-responsive">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={data}
                  className="ant-border-space"
                  pagination={{
                    position: ['topCenter', 'bottomCenter'],
                    total,
                    pageSize,
                    current: pageNumber,
                    onChange: handleChangePagination,
                    showSizeChanger: true,
                    onShowSizeChange: handleChangePagination,
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
                  }}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <Modal
        title={modalType === 'ADD' ? 'Create new school' : `Editing school "${selected?.name}"`}
        open={visible}
        onCancel={() => toggleModal(false)}
        onOk={() => form.submit()}
      >
        <Form form={form} {...layout} onFinish={modalType === 'ADD' ? createSchool : updateSchool}>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please input name'
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Short Name"
            name="short_name"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Address"
            name="address"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default Schools
