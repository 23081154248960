import CalendarAPI from '../../api/CalendarAPI'
import CurriculumUnitAPI from '../../api/CurriculumUnitAPI'
import TeacherAPI from '../../api/TeacherAPI'
let eventGuid = 0
let todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today

export const INITIAL_EVENTS = [
  {
    id: createEventId(),
    title: 'All-day event',
    start: todayStr
  },
  {
    id: createEventId(),
    title: 'Timed event',
    start: todayStr + 'T12:00:00',
    end: todayStr + 'T14:00:00'
  }
]

export function createEventId() {
  return String(eventGuid++)
}
export const getTitle = (teachers, curriculumUnits, values) => {
  return (
    teachers.find((c) => c.value === values.teacher_id).label +
    ' - ' +
    curriculumUnits.find((c) => c.value === values.curriculum_unit_id).label
  )
}
export const fetchCurriculumUnits = async (search) => {
  const res = await CurriculumUnitAPI.getCurriculumUnits({
    page_number: 1,
    page_size: 100,
    search
  })
  const curriculumUnit = res.rows.map((i) => ({
    label: i.name,
    value: i.id
  }))
  return curriculumUnit
}
export const fetchTeachers = async (search) => {
  const res = await TeacherAPI.getTeachers({
    page_number: 1,
    page_size: 100,
    search
  })
  const teacher = res.rows.map((i) => ({
    label: i.full_name,
    value: i.id
  }))
  return teacher
}
export const fetchAdminCalendar = async (range) => {
  const res = await CalendarAPI.getAdminCalendars({
    start_time: range?.start,
    end_time: range?.end
  })
  return res.rows
}
export const fetchStudentCalendar = async () => {
  const res = await CalendarAPI.getStudentCalendars({})
  return res.rows
}
export const fetchTeacherCalendar = async (range) => {
  const res = await CalendarAPI.getTeacherCalendars({
    start_time: range?.start,
    end_time: range?.end
  })
  return res.rows
}
