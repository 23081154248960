import { Modal } from "antd";
import { useMemo, useState } from "react";

export default function MaterialViewModal({ open, onCancel, title, material }) {
  const isPpt = useMemo(() => material?.type === "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    || material?.type === "application/vnd.ms-powerpoint", [material?.type]);
  const [fullScreenBtnVisible, showFullScreenBtn] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  return (
    <Modal
      title={material?.name || title}
      open={open}
      onCancel={onCancel}
      width={fullScreen ? 'calc(100vw - 32px)' : "1000px"}
      footer={null}
      destroyOnClose
      centered
    >
      <div style={{
        position: 'relative', width: '100%',
        height: fullScreen ? 'calc(100vh - 115px)' : 600,
        backgroundColor: '#e5e7e8',
      }}
        onMouseMove={() => showFullScreenBtn(true)}
        onMouseLeave={() => showFullScreenBtn(false)}>
        {isPpt ?
          <>
            <iframe
              src={"https://docs.google.com/presentation/d/" + material?.content + "/preview"}
              width="100%"
              height="100%"
              title={title}
              allowFullScreen
              style={{ border: 'none' }}
            />
            <div style={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              width: 130,
              height: 36,
              backgroundColor: '#e5e7e8',
            }}>
            </div>
            <div style={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              width: 36,
              height: 36,
              padding: 6,
              backgroundColor: '#0c0c0c',
              cursor: 'pointer',
              visibility: fullScreenBtnVisible ? 'visible' : 'hidden'
            }}
              onClick={() => setFullScreen(v => !v)}
            >
              <div style={{
                backgroundImage: "url('https://ssl.gstatic.com/docs/common/viewer/v3/v-sprite51.svg')",
                backgroundPosition: '0 -2536px',
                height: 24,
                width: 24,
              }}></div>
            </div>
          </>
          :
          <>
            <iframe
              src={"https://drive.google.com/file/d/" + material?.content + "/preview"}
              width="100%"
              height="100%"
              title={title}
              allowFullScreen
              style={{ border: 'none' }}
            />
            <div style={{
              position: 'absolute',
              top: 12,
              right: 12,
              width: 40,
              height: 40,
              padding: 8,
              backgroundColor: '#0c0c0c',
              cursor: 'pointer',
              visibility: fullScreenBtnVisible ? 'visible' : 'hidden'
            }}
              onClick={() => setFullScreen(v => !v)}
            >
              <div style={{
                backgroundImage: "url('https://ssl.gstatic.com/docs/common/viewer/v3/v-sprite51.svg')",
                backgroundPosition: '0 -2536px',
                height: 24,
                width: 24,
              }}></div>
            </div>
          </>
        }
      </div>
    </Modal>
  )
}
