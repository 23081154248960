import {
    Row,
    Col,
    Card,
    Table,
    Space,
    Button,
    notification,
    Tag,
    Modal,
    Form,
    Input,
    Divider,
    Popconfirm
} from 'antd'

import { useCallback, useEffect, useState } from 'react'
import ClassAPI from '../../api/ClassAPI'
import moment from 'moment'
import _ from 'lodash'

const { Search } = Input

function Class() {
    const [form] = Form.useForm()
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const [modalType, setModalType] = useState('ADD')
    const [selected, setSelected] = useState(null)
    const [pageNumber, setPageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [total, setTotal] = useState(0)
    const [search, setSearch] = useState('')
    const [visibleCurriculumModal, setVisibleCurriculumModal] = useState(false)
    const [visibleStudentModal, setVisibleStudentModal] = useState(false)

    const fetchData = useCallback(
        async (query) => {
            setLoading(true)
            ClassAPI.teacherGetClasses({
                page_number: query?.page_number || pageNumber,
                page_size: query?.page_size || pageSize,
                search: query?.search || search
            })
                .then((res) => {
                    setTotal(res.count)
                    setData(
                        res.rows.map((item) => ({
                            ...item,
                            key: item.id
                        }))
                    )
                    setLoading(false)
                })
                .catch((err) => console.log(err))
                .finally(() => setLoading(false))
        }, [pageNumber, pageSize, search]
    )

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const toggleModal = (v) => {
        setVisible(v)
    }
    const toggleCurriculumModal = (v) => {
        setVisibleCurriculumModal(v)
    }
    const toggleStudentModal = (v) => {
        setVisibleStudentModal(v)
    }

    const handleChangePagination = useCallback((page_number, page_size) => {
        setPageNumber(page_number)
        setPageSize(page_size)
        fetchData({
            page_number,
            page_size,
            search: search
        })
    }, [fetchData, search])

    const handleSearchString = useCallback((value) => {
        setSearch(value)
        fetchData({
            search: value,
            page_number: pageNumber,
            page_size: pageSize
        })
    }, [fetchData, pageNumber, pageSize])

    const studentColumns = [
        {
            title: 'STT',
            dataIndex: 'id',
            key: 'id',
            width: '5%',
            render: (text, record, index) => index + 1
        },
        {
            title: 'Email',
            dataIndex: 'user',
            key: 'user',
            width: '32%',
            render: (text, record) => text?.email
        },
        {
            title: 'Name',
            dataIndex: 'full_name',
            key: 'full_name',
            width: '32%',
            render: (text, record) => text
        },
        {
            title: 'DoB',
            dataIndex: 'date_of_birth',
            key: 'date_of_birth',
            width: '32%',
            render: (text, record) => moment(text).format('DD/MM/YYYY')
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            width: '32%',
            render: (text, record) => text
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            width: '32%',
            render: (text, record) => text
        },
    ]
    const curriculumColumns = [
        {
            title: 'STT',
            dataIndex: 'id',
            key: 'id',
            width: '5%',
            render: (text, record, index) => index + 1
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '20%',
            render: (text, record) => text
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            width: '20%',
            render: (text, record) => text
        },
        {
            title: 'Units',
            dataIndex: 'curriculums_units',
            key: 'curriculums_units',
            width: '20%',
            render: (text, record) => (
                <Tag color="cyan">{text?.length + ' units'}</Tag>
            )
        },
    ]
    const columns = [
        {
            title: 'STT',
            dataIndex: 'id',
            key: 'id',
            width: '5%',
            render: (text, record, index) => index + 1
        },
        {
            title: 'Name',
            dataIndex: 'class',
            key: 'class',
            width: '32%',
            render: (text, record) => text?.name
        },
        {
            title: 'Description',
            dataIndex: 'class',
            key: 'class',
            width: '32%',
            render: (text, record) => text?.description
        },
        {
            title: 'School',
            dataIndex: 'class',
            key: 'class',
            width: '32%',
            render: (text, record) => text?.school?.name
        },
        {
            title: 'Class Group',
            dataIndex: 'class',
            key: 'class',
            width: '32%',
            render: (text, record) => text?.class_group?.name
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            width: '20%',
            align: 'center',
            render: (value, record) => {
                return (
                    <Space>
                        <Button
                            type="primary"
                            size="small"
                            onClick={() => {
                                setSelected(record)
                                toggleStudentModal(true)
                            }}
                        >
                            View Students
                        </Button>
                        <Button
                            type="primary"
                            size="small"
                            onClick={() => {
                                setSelected(record)
                                toggleCurriculumModal(true)
                            }}
                        >
                            View Curriculums
                        </Button>
                    </Space>
                )
            }
        }
    ]

    return (
        <>
            <div className="tabled">
                <Row gutter={[24, 0]}>
                    <Col xs="24" xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace mb-24"
                            title="Class table"
                            extra={null}
                        >
                            <div className="table-responsive">
                                <Table
                                    loading={loading}
                                    columns={columns}
                                    dataSource={data}
                                    className="ant-border-space"
                                    pagination={{
                                        position: ['topCenter', 'bottomCenter'],
                                        total,
                                        pageSize,
                                        current: pageNumber,
                                        onChange: handleChangePagination,
                                        showSizeChanger: true,
                                        onShowSizeChange: handleChangePagination,
                                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
                                    }}
                                />
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
            <Modal
                title='Students list'
                open={visibleStudentModal}
                onCancel={() => toggleStudentModal(false)}
                onOk={() => toggleStudentModal(false)}
                width={1000}
            >
                <Table
                    scroll={{ y: 500, x: 1000 }}
                    dataSource={selected?.class?.students}
                    columns={studentColumns}
                    className="ant-border-space"
                    pagination={false}
                />
            </Modal>
            <Modal
                title='Curriculum list'
                open={visibleCurriculumModal}
                onCancel={() => toggleCurriculumModal(false)}
                onOk={() => toggleCurriculumModal(false)}
                width={1000}
            >
                <Table
                    scroll={{ y: 500, x: 1000 }}
                    dataSource={selected?.class?.curriculums}
                    columns={curriculumColumns}
                    className="ant-border-space"
                    pagination={false}
                />
            </Modal>
        </>
    )
}

export default Class
