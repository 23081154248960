import React, { useCallback, useState, useEffect } from 'react'
//ant design modal to import/export data
import { Modal, Form, Button, Upload, Space, notification, message } from 'antd'
import DebounceSelect from '../DebounceSelect'
import ClassAPI from '../../api/ClassAPI'
import StudentAPI from '../../api/StudentAPI'
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons'
import ApiSender from '../../api/ApiSender'

const ImportExport = ({ open, type, close, role = 'admin', class_id }) => {
  const [selectedClassId, setSelectedClassId] = useState(null)
  const [selectedFile, setSelectedFile] = useState(null)

  const fetchClasses = useCallback(async (search) => {
    const res = await ClassAPI.getClasses({
      page_number: 1,
      page_size: 100,
      search
    })
    return res.rows.map((i) => ({
      label: i.name + ' - ' + i.school.name,
      value: i.id
    }))
  }, [])

  const exportStudents = () => {
    StudentAPI.exportStudents({
      class_id: selectedClassId || class_id,
      role: role
    })
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleUpload = () => {
    if (!selectedClassId && !class_id) {
      message.error('Please select class')
      return
    }
    if (!selectedFile) {
      message.error('Please select file')
      return
    }
    StudentAPI.importStudents(selectedFile, {
      class_id: selectedClassId || class_id,
      role
    })
      .then((res) => {
        message.success('Import success')
      })
      .catch((err) => {
        message.error('Import failed')
      })
  }
  return (
    <Modal
      title={type}
      open={open}
      footer={[
        type === 'IMPORT_STUDENT' ? (
          <Button key="submit" type="primary" onClick={handleUpload}>
            Import
          </Button>
        ) : null,
        <Button
          key="back"
          onClick={() => {
            setSelectedClassId(null)
            setSelectedFile(null)
            close()
          }}
        >
          Close
        </Button>
      ]}
      onCancel={() => {
        setSelectedClassId(null)
        setSelectedFile(null)
        close()
      }}
    >
      {type === 'EXPORT_STUDENT' ? (
        <>
          {!class_id ? (
            <Form.Item label="Class" name="class_id">
              <DebounceSelect
                onChange={(value) => setSelectedClassId(value)}
                placeholder="Select class"
                fetchOptions={fetchClasses}
                allowClear
              />
            </Form.Item>
          ) : null}
          <Form.Item label="Action">
            <Button
              onClick={exportStudents}
              type="primary"
              icon={<DownloadOutlined />}
              size="small"
            >
              Export
            </Button>
          </Form.Item>
        </>
      ) : type === 'IMPORT_STUDENT' ? (
        <>
          {!class_id ? (
            <Form.Item label="Class" name="class_id">
              <DebounceSelect
                onChange={(value) => setSelectedClassId(value)}
                placeholder="Select class"
                fetchOptions={fetchClasses}
                allowClear
              />
            </Form.Item>
          ) : null}
          <Form.Item label="Action">
            <Space>
              <Upload
                beforeUpload={(file) => {
                  setSelectedFile(file)
                  return false
                }}
                onRemove={() => setSelectedFile(null)}
                multiple={false}
              >
                {!selectedFile && (
                  <Button icon={<UploadOutlined />}>Select File</Button>
                )}
              </Upload>
              <a
                href={ApiSender.getApiRoot(
                  '/core/templates/import_student_template.xlsx'
                )}
              >
                <Button icon={<DownloadOutlined />}>Download Template</Button>
              </a>
            </Space>
          </Form.Item>
        </>
      ) : null}
    </Modal>
  )
}

export default ImportExport
