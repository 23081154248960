import { Menu } from 'antd'
import jwtDecode from 'jwt-decode'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import {
  FaBook,
  FaBookOpen,
  FaBuilding,
  FaCalendarAlt,
  FaLaptopHouse,
  FaUser,
  FaUserGraduate,
  FaUserSecret,
  FaUserTie
} from 'react-icons/fa'
import { NavLink, useLocation } from 'react-router-dom'
import ClassAPI from '../../api/ClassAPI'
import logo from '../../assets/images/i2g_logo_web.png'
import { useAuthState } from '../../context/AuthContext'

function Sidenav({ color }) {
  const { pathname } = useLocation()
  const page = pathname.replace('/', '')
  const { token } = useAuthState()
  const { role } = token ? jwtDecode(token) : { role: null }
  const [tClasses, setTClasses] = useState([])

  useEffect(() => {
    async function fetchClasses() {
      if (role === 'teacher') {
        const response = await ClassAPI.teacherGetClasses({
          page_number: 1,
          page_size: 1000
        })
        const tClasses = response.rows.map((item) => ({
          ...item,
          key: item.class_id
        }))
        setTClasses(tClasses)
      }
    }
    fetchClasses()
  }, [role])
  const navItemsStudent = [
    {
      key: '1',
      label: (
        <NavLink to="/student/curriculums">
          <span
            className="icon"
            style={{
              background: page === '/student/curriculums' ? color : ''
            }}
          >
            <FaBookOpen />
          </span>
          <span className="label">Giáo trình môn học</span>
        </NavLink>
      )
    }
  ]
  const navItemsTeacher = tClasses.map((item) => ({
    key: item.key,
    label: (
      <NavLink to={`/teacher/class/${item?.class_id}`} title={item?.class?.name + ' - ' + item?.class?.school?.name}>
        <span
          className="icon"
          style={{
            background: page === `/teacher/class/${item.class_id}` ? color : ''
          }}
        >
          <FaLaptopHouse />
        </span>
        <span className="label">{item?.class?.name + ' - ' + item?.class?.school?.name}</span>
      </NavLink>
    )
  }))
  const navItemsTeacherExtend = [
    {
      key: 'calendar',
      label: (
        <NavLink to="/teacher/calendar">
          <span
            className="icon"
            style={{
              background: page === 'teacher/calendar' ? color : ''
            }}
          >
            <FaCalendarAlt />
          </span>
          <span className="label">Lịch</span>
        </NavLink>
      )
    }
  ]
  const navItemsAdmin = [
    {
      key: '2.1',
      label: (
        <NavLink to="/admin/admins">
          <span
            className="icon"
            style={{
              background: page === 'admin/admins' ? color : ''
            }}
          >
            <FaUserSecret />
          </span>
          <span className="label">Admins</span>
        </NavLink>
      )
    },
    {
      key: '2',
      label: (
        <NavLink to="/admin/schools">
          <span
            className="icon"
            style={{
              background: page === 'admin/schools' ? color : ''
            }}
          >
            <FaBuilding />
          </span>
          <span className="label">Trường</span>
        </NavLink>
      )
    },
    {
      key: '4',
      label: (
        <NavLink to="/admin/class-groups">
          <span
            className="icon"
            style={{
              background: page === 'admin/class-groups' ? color : ''
            }}
          >
            <FaLaptopHouse />
          </span>
          <span className="label">Khối lớp</span>
        </NavLink>
      )
    },
    {
      key: '3',
      label: (
        <NavLink to="/admin/classes">
          <span
            className="icon"
            style={{
              background: page === 'admin/classes' ? color : ''
            }}
          >
            <FaLaptopHouse />
          </span>
          <span className="label">Lớp</span>
        </NavLink>
      )
    },
    {
      key: '5',
      label: (
        <NavLink to="/admin/teachers">
          <span
            className="icon"
            style={{
              background: page === 'admin/teachers' ? color : ''
            }}
          >
            <FaUserTie />
          </span>
          <span className="label">Giáo viên</span>
        </NavLink>
      )
    },
    {
      key: '6',
      label: (
        <NavLink to="/admin/students">
          <span
            className="icon"
            style={{
              background: page === 'admin/students' ? color : ''
            }}
          >
            <FaUserGraduate />
          </span>
          <span className="label">Học sinh</span>
        </NavLink>
      )
    },
    {
      key: '7',
      label: (
        <NavLink to="/admin/curriculums">
          <span
            className="icon"
            style={{
              background: page === 'admin/curriculums' ? color : ''
            }}
          >
            <FaBookOpen />
          </span>
          <span className="label">Giáo trình môn học</span>
        </NavLink>
      )
    },
    {
      key: '8',
      label: (
        <NavLink to="/admin/curriculum-units">
          <span
            className="icon"
            style={{
              background: page === 'admin/curriculum-units' ? color : ''
            }}
          >
            <FaBook />
          </span>
          <span className="label">Bài giảng</span>
        </NavLink>
      )
    },
    {
      key: '9',
      label: (
        <NavLink to="/admin/calendar">
          <span
            className="icon"
            style={{
              background: page === 'admin/calendar' ? color : ''
            }}
          >
            <FaCalendarAlt />
          </span>
          <span className="label">Lịch</span>
        </NavLink>
      )
    }
  ]
  const navItemCommonSettings = [
    {
      key: '998',
      className: 'menu-item-header',
      label: 'Cài đặt'
    },
    {
      key: '999',
      label: (
        <NavLink to="/profile">
          <span
            className="icon"
            style={{
              background: page === 'profile' ? color : ''
            }}
          >
            <FaUser />
          </span>
          <span className="label">Thông tin cá nhân</span>
        </NavLink>
      )
    }
  ]
  return (
    <>
      <div className="brand">
        <img src={logo} alt="" />
        <span>LMS</span>
      </div>
      <hr />
      <Menu
        theme="light"
        mode="inline"
        items={_.union(
          role === 'admin'
            ? navItemsAdmin
            : role === 'student'
              ? navItemsStudent
              : role === 'teacher'
                ? navItemsTeacher.concat(navItemsTeacherExtend)
                : [],
          navItemCommonSettings
        )}
      ></Menu>
    </>
  )
}

export default Sidenav
