import React from 'react'
import { Modal, Form, Input, notification } from 'antd'
import UserAPI from '../../api/UserAPI'

const ChangePasswordModal = ({ info, open, close, action }) => {
  // const { formatMessage: tr } = useIntl();
  const [form] = Form.useForm()

  const onSave = () => {
    form.validateFields().then(async (values) => {
      try {
        if (action === 'UPDATE') {
          await UserAPI.updateProfile(values, info?.id)
          notification['success']({
            message: 'Success',
            description: 'Change password successfully'
          })
          form.resetFields();
          close();
        } else if (action === "RESET") {
          await UserAPI.resetPassword({ user_id: info?.id, ...values })
          notification['success']({
            message: 'Success',
            description: 'Reset password successfully'
          })
          form.resetFields();
          close();
        } else {
          notification['error']({
            message: 'Error',
            description: 'Unknown action'
          })
        }
      } catch (e) {
        console.log(e)
      }
    })
  }
  return (
    <Modal
      title="Change Password"
      open={open}
      onClose={close}
      onOk={onSave}
      onCancel={close}
      okText="Save"
    >
      <Form
        form={form}
        onFinish={onSave}
        {...{
          labelCol: { span: 7 },
          wrapperCol: { span: 14 }
        }}
      >
        {action === 'UPDATE' ? <Form.Item
          label="Current Password"
          name="oldPassword"
          rules={[
            {
              required: true,
              message: 'Please input your current password!'
            }
          ]}
        >
          <Input.Password />
        </Form.Item> : null}
        <Form.Item
          label="New Password"
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your new password!'
            }
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Re-Password"
          name="rePassword"
          rules={[
            {
              required: true,
              message: 'Please input your re password!'
            }
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ChangePasswordModal
