import { Card } from 'antd'
import moment from 'moment'
import React, { useCallback, useState } from 'react'
import CurriculumUnitAPI from '../../api/CurriculumUnitAPI'
import RootCalendar from '../../components/calendar/RootCalendar'
import { fetchTeacherCalendar } from '../../components/calendar/event-utils'

function Calendar() {
  const [calendars, setCalendars] = useState([])
  const fetchCurriculumUnits = async () => {
    const res = await CurriculumUnitAPI.teacherGetCurriculumUnits({
      page_number: 1,
      page_size: 100,
    })
    const curriculumUnits = []
    res.rows.forEach((i) => {
      i.curriculum.curriculums_units.forEach((j) =>
        curriculumUnits.push({
          label: j.name,
          value: j.id
        })
      )
    })
    return curriculumUnits
  }
  const fetchInitCalendar = useCallback(async (range) => {
    const ca = await fetchTeacherCalendar(range)
    const initCalendars = await ca.map((i) => ({
      id: i.id.toString(),
      title: i.curriculums_unit.name,
      start: moment(i.start_time).format().replace(/\+.*$/, ''),
      end: moment(i.end_time).format().replace(/\+.*$/, ''),
      extendedProps: {
        curriculum_unit_id: i.curriculum_unit_id,
        teacher_id: i.teacher_id,
        teacher_check_in: i.teacher_check_in,
        teacher_check_out: i.teacher_check_out
      }
    }))
    setCalendars(initCalendars)
  }, [])

  return (
    <Card className="tablespace mb-24">
      <RootCalendar
        onlyEdit
        calendars={calendars}
        fetchInitData={fetchInitCalendar}
        fetchCurriculumUnits={fetchCurriculumUnits}
      />
    </Card>
  )
}
export default Calendar
