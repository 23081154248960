import ApiSender from './ApiSender'

export default class CurriculumGradeAPI {
  static getCurriculumGrades(query) {
    const route = '/core/admin/curriculum-grade'
    return ApiSender.get(route, query)
  }
  static upsertCurriculumGrade(data) {
    const route = '/core/admin/curriculum-grade'
    return ApiSender.post(route, data)
  }
  static deleteCurriculumGrade(data) {
    const route = '/core/admin/curriculum-grade/' + data.id
    return ApiSender.delete(route)
  }
  static studentGetCurriculumGrades(query) {
    const route = '/core/student/curriculum-grade'
    return ApiSender.get(route, query)
  }
  static teacherGetStudentCurriculumGradesByClass(query) {
    const route = '/core/teacher/student-curriculum-grades-by-class'
    return ApiSender.get(route, query)
  }
  static teacherUpsertCurriculumGrade(data) {
    const route = '/core/teacher/curriculum-grade'
    return ApiSender.post(route, data)
  }
  static teacherDeleteCurriculumGrade(data) {
    const route = '/core/teacher/curriculum-grade/' + data.id
    return ApiSender.delete(route)
  }
  static teacherGetCurriculumGradeTypes(query) {
    const route = '/core/teacher/curriculum-grade-type'
    return ApiSender.get(route, query)
  }
  static teacherUpsertCurriculumGradeType(data) {
    const route = '/core/teacher/curriculum-grade-type'
    return ApiSender.post(route, data)
  }
  static teacherDeleteCurriculumGradeType(data) {
    const route = '/core/teacher/curriculum-grade-type/' + data.id
    return ApiSender.delete(route)
  }
}
