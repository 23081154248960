import {
  Row,
  Col,
  Card,
  Table,
  Space,
  Button,
  notification,
  Tag,
  Modal,
  Form,
  Input,
  Popconfirm,
  DatePicker
} from 'antd'

import { useCallback, useEffect, useState } from 'react'
import TeacherAPI from '../../api/TeacherAPI'
import SchoolAPI from '../../api/SchoolAPI'
import ClassAPI from '../../api/ClassAPI'
import moment from 'moment'
import DebounceSelect from '../../components/DebounceSelect'
import ChangePasswordModal from '../../components/profile/ChangePasswordModal'
import { ItemMultipleSelect } from '../../components/MultipleSelect'
const { Search } = Input

function Teacher() {
  const [form] = Form.useForm()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [modalType, setModalType] = useState('ADD')
  const [selected, setSelected] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(0)
  const [search, setSearch] = useState('')
  const [showChangePassword, setShowChangePassword] = useState(false)

  const fetchData = useCallback(
    async (query) => {
      setLoading(true)
      TeacherAPI.getTeachers({
        page_number: query?.page_number || pageNumber,
        page_size: query?.page_size || pageSize,
        search: query?.search || search
      })
        .then((res) => {
          setTotal(res.count)
          setData(
            res.rows.map((item) => ({
              ...item,
              key: item.id
            }))
          )
          setLoading(false)
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false))
    },
    [pageNumber, pageSize, search]
  )

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const toggleModal = (v) => {
    setVisible(v)
  }

  const onButtonClick = (selected, type) => {
    if (type === 'ADD') {
      form.resetFields()
      setModalType('ADD')
      toggleModal(true)
    }
    if (type === 'DELETE') {
      TeacherAPI.deleteTeacher({ id: selected.id })
        .then((res) => {
          notification['success']({
            message: 'Success',
            description: 'Delete teacher successfully'
          })
          fetchData()
        })
        .catch((err) =>
          notification['error']({
            message: 'Error',
            description: err.message
          })
        )
    }
    if (type === 'EDIT') {
      form.resetFields()
      setModalType('EDIT')
      selected.date_of_birth = moment(selected.date_of_birth)
      selected.username = selected.user?.username
      selected.email = selected.user?.email
      setSelected(selected)
      form.setFieldsValue({
        class_id: selected?.classes?.map((item) => item.id)
      })
      form.setFieldsValue(selected)
      toggleModal(true)
    }
  }

  const createTeacher = (values) => {
    TeacherAPI.createTeacher({ ...values })
      .then((res) => {
        notification['success']({
          message: 'Success',
          description: 'Create teacher successfully'
        })
        fetchData()
        toggleModal(false)
      })
      .catch((err) => {
        notification['error']({
          message: 'Error',
          description: err.message
        })
      })
  }

  const updateTeacher = (values) => {
    TeacherAPI.updateTeacher({ ...values, id: selected.id })
      .then((res) => {
        notification['success']({
          message: 'Success',
          description: 'Update teacher successfully'
        })
        fetchData()
        toggleModal(false)
      })
      .catch((err) => {
        notification['error']({
          message: 'Error',
          description: err.message
        })
      })
  }

  const handleChangePagination = useCallback(
    (page_number, page_size) => {
      setPageNumber(page_number)
      setPageSize(page_size)
      fetchData({
        page_number,
        page_size,
        search: search
      })
    },
    [fetchData, search]
  )

  const handleSearchString = useCallback(
    (value) => {
      setSearch(value)
      fetchData({
        search: value,
        page_number: pageNumber,
        page_size: pageSize
      })
    },
    [fetchData, pageNumber, pageSize]
  )

  const columns = [
    {
      title: 'STT',
      dataIndex: 'id',
      key: 'id',
      width: '5%',
      render: (text, record, index) => index + 1
    },
    {
      title: 'Email',
      dataIndex: 'user',
      key: 'user',
      width: '32%',
      render: (text, record) => text?.email
    },
    {
      title: 'Tên',
      dataIndex: 'full_name',
      key: 'full_name',
      width: '32%',
      render: (text, record) => text
    },
    {
      title: 'Ngày sinh',
      dataIndex: 'date_of_birth',
      key: 'date_of_birth',
      width: '32%',
      render: (text, record) => moment(text).format('DD/MM/YYYY')
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'address',
      key: 'address',
      width: '32%',
      render: (text, record) => text
    },
    {
      title: 'SĐT',
      dataIndex: 'phone',
      key: 'phone',
      width: '32%',
      render: (text, record) => text
    },

    {
      title: 'Trường',
      dataIndex: 'school',
      key: 'school',
      width: '32%',
      render: (text, record) => text?.name
    },
    {
      title: 'Lớp',
      dataIndex: 'classes',
      key: 'classes',
      width: '20%',
      render: (text, record) =>
        text?.map((item) => (
          <>
            <Tag color="blue">{item.name + ' - ' + item?.school?.name}</Tag>
            <br />
          </>
        ))
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: '20%',
      align: 'center',
      render: (value, record) => {
        return (
          <Space>
            <Button
              type="primary"
              size="small"
              onClick={() => onButtonClick(record, 'EDIT')}
            >
              Sửa
            </Button>
            <Popconfirm
              title="Chắc chắn xoá?"
              onConfirm={() => onButtonClick(record, 'DELETE')}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" danger size="small">
                Xoá
              </Button>
            </Popconfirm>
          </Space>
        )
      }
    }
  ]
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
  }
  const fetchSchool = useCallback(async (search) => {
    const res = await SchoolAPI.getSchools({
      page_number: 1,
      page_size: 100,
      search
    })
    return res.rows.map((i) => ({
      label: i.name,
      value: i.id
    }))
  }, [])
  const fetchClasses = useCallback(async (search) => {
    const res = await ClassAPI.getClasses({
      page_number: 1,
      page_size: 100,
      search
    })
    return res.rows.map((i) => ({
      label: i.name + ' - ' + i.school.name,
      value: i.id
    }))
  }, [])

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Danh sách giáo viên"
              extra={
                <Space size="small">
                  <Search
                    placeholder="Nhập để tìm"
                    onSearch={handleSearchString}
                    enterButton="Tìm"
                    allowClear
                  />
                  <Button
                    type="primary"
                    onClick={() => onButtonClick(null, 'ADD')}
                    title="Add new teacher"
                  >
                    Thêm mới
                  </Button>
                </Space>
              }
            >
              <div className="table-responsive">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={data}
                  className="ant-border-space"
                  pagination={{
                    position: ['topCenter', 'bottomCenter'],
                    total,
                    pageSize,
                    current: pageNumber,
                    onChange: handleChangePagination,
                    showSizeChanger: true,
                    onShowSizeChange: handleChangePagination,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`
                  }}
                />
              </div>
            </Card>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24} md={24} sm={24}></Col>
        </Row>
      </div>
      <Modal
        title={
          modalType === 'ADD'
            ? 'Create new teacher'
            : `Editing teacher "${selected?.full_name}"`
        }
        open={visible}
        onCancel={() => toggleModal(false)}
        onOk={() => form.submit()}
      >
        <Form
          form={form}
          {...layout}
          onFinish={modalType === 'ADD' ? createTeacher : updateTeacher}
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[
              {
                required: true,
                message: 'Please input username'
              }
            ]}
          >
            <Input disabled={modalType === 'EDIT'} />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: 'Please input email'
              }
            ]}
          >
            <Input disabled={modalType === 'EDIT'} />
          </Form.Item>
          <Form.Item
            label="Full Name"
            name="full_name"
            rules={[
              {
                required: true,
                message: 'Please input full name'
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Date of birth"
            name="date_of_birth"
            rules={[
              {
                required: true,
                message: 'Please input date of birth'
              }
            ]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item label="Address" name="address">
            <Input />
          </Form.Item>
          <Form.Item label="Phone" name="phone">
            <Input />
          </Form.Item>
          <ItemMultipleSelect
            label="Classes"
            name="class_id"
            fetchOptions={fetchClasses}
            placeholder="Select classes"
            allowClear
          />
          <Form.Item
            label="School"
            name="school_id"
            rules={[
              {
                required: true,
                message: 'Please input school'
              }
            ]}
          >
            <DebounceSelect
              placeholder="Select school"
              fetchOptions={fetchSchool}
              allowClear
            />
          </Form.Item>
          {modalType !== 'EDIT' ? (
            <>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please input password'
                  }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Re-password"
                name="rePassword"
                rules={[
                  {
                    required: true,
                    message: 'Please input re-password'
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </>
          ) : (
            <Form.Item label="Password">
              <Button type="dashed" onClick={() => setShowChangePassword(true)}>
                Reset Password
              </Button>
            </Form.Item>
          )}
        </Form>
      </Modal>
      <ChangePasswordModal
        info={selected}
        open={showChangePassword}
        close={() => setShowChangePassword(false)}
        action="RESET"
      />
    </>
  )
}

export default Teacher
