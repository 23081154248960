import { useState, useCallback, useEffect } from 'react'

import { Row, Col, Card, Button, Descriptions, Avatar } from 'antd'

import BgProfile from '../../assets/images/bg-signup.jpg'

import UserAPI from '../../api/UserAPI'
import UpdateProfile from '../../components/profile/UpdateProfile'
import AvatarCropUpload from '../../components/profile/AvatarCropUpload'
import { KeyOutlined } from '@ant-design/icons'
import ChangePasswordModal from '../../components/profile/ChangePasswordModal'

function Profile() {
  const [loading, setLoading] = useState(false)
  const [fullInfo, setFullInfo] = useState(null)
  const [showEditProfile, setShowEditProfile] = useState(false)
  const [showChangePassword, setShowChangePassword] = useState(false)
  const [isUpdated, setisUpdated] = useState(false)

  const fetchData = useCallback(async (query) => {
    setLoading(true)
    UserAPI.myInfo({})
      .then((res) => {
        setFullInfo(res)
        setLoading(false)
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    fetchData()
    if (isUpdated) {
      fetchData()
      setisUpdated(false)
    }
  }, [fetchData, isUpdated])

  const pencil = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"
        className="fill-gray-7"
      ></path>
      <path
        d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"
        className="fill-gray-7"
      ></path>
    </svg>
  ]
  return (
    <>
      <div
        className="profile-nav-bg"
        style={{ backgroundImage: 'url(' + BgProfile + ')' }}
      ></div>
      <Card
        className="card-profile-head"
        bodyStyle={{ display: 'none' }}
        title={
          <Row justify="space-between" align="middle" gutter={[24, 0]}>
            <Col span={24} md={12} className="col-info">
              <Avatar.Group>
                <AvatarCropUpload info={fullInfo} />
                <div className="avatar-info">
                  <h4 className="font-semibold m-0">{fullInfo?.full_name} </h4>
                  <p>{fullInfo?.school?.name || 'Administrator'}</p>
                  <Button
                    type="link"
                    color="gray"
                    onClick={() => setShowChangePassword(true)}
                    style={{ padding: 0 }}
                  >
                    Đổi mật khẩu
                  </Button>
                </div>
              </Avatar.Group>
            </Col>
          </Row>
        }
      ></Card>
      <div className="card-profile-body">
        <Row className="ml-12">
          <Col>
            <Card
              bordered={false}
              title={<h6 className="font-semibold m-0">Thông tin cá nhân</h6>}
              className="header-solid h-full card-profile-information"
              extra={
                <Button type="link" onClick={() => setShowEditProfile(true)}>
                  {pencil}
                </Button>
              }
              bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
            >
              <Descriptions>
                <Descriptions.Item label="Email" span={3}>
                  {fullInfo?.email}
                </Descriptions.Item>
                <Descriptions.Item label="Username" span={3}>
                  {fullInfo?.username}
                </Descriptions.Item>
                <Descriptions.Item label="Role" span={3}>
                  {fullInfo?.role}
                </Descriptions.Item>
                {fullInfo?.role !== 'admin' && (
                  <Descriptions.Item label="Trường" span={3}>
                    {fullInfo?.school?.name}
                  </Descriptions.Item>
                )}
                <Descriptions.Item label="Họ tên" span={3}>
                  {fullInfo?.full_name}
                </Descriptions.Item>
                <Descriptions.Item label="Ngày sinh" span={3}>
                  {fullInfo?.date_of_birth}
                </Descriptions.Item>
                <Descriptions.Item label="SĐT" span={3}>
                  {fullInfo?.phone}
                </Descriptions.Item>
                <Descriptions.Item label="Địa chỉ" span={3}>
                  {fullInfo?.address}
                </Descriptions.Item>
                {/* <Descriptions.Item label="Vị trí" span={3}>
                  Viet Nam
                </Descriptions.Item> */}
              </Descriptions>
            </Card>
          </Col>
        </Row>
      </div>
      <UpdateProfile
        info={fullInfo}
        open={showEditProfile}
        close={() => setShowEditProfile(false)}
        fetchData={() => setisUpdated(true)}
      />
      <ChangePasswordModal
        info={fullInfo}
        open={showChangePassword}
        close={() => setShowChangePassword(false)}
        action='UPDATE'
      />
    </>
  )
}

export default Profile
