import ApiSender from './ApiSender'

export default class AdminAPI {
  static getAdmins(query) {
    const route = '/core/admin/admins'
    return ApiSender.get(route, query)
  }
  static createAdmin(data) {
    const route = '/core/admin/admins'
    return ApiSender.post(route, data)
  }
  static getAdmin(data) {
    const route = '/core/admin/admins/' + data?.id
    return ApiSender.get(route)
  }
  static updateAdmin(data) {
    const route = '/core/admin/admins/' + data.id
    return ApiSender.put(route, data)
  }
  static deleteAdmin(data) {
    const route = '/core/admin/admins/' + data?.id
    return ApiSender.delete(route)
  }
}
