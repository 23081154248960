import ApiSender from './ApiSender'

export default class UserAPI {
  static login(query) {
    const route = '/core/login'
    return ApiSender.post(route, query)
  }
  static myInfo(query) {
    const route = '/core/admin/users/my-info'
    return ApiSender.get(route, query)
  }
  static updateUser(data, id){
    const route = '/core/admin/users/' + id
    return ApiSender.put(route, data)
  }
  static updateProfile(data, id){
    const route = '/core/admin/users/' + id + '/profile'
    return ApiSender.put(route, data)
  }
  static resetPassword(data){
    const route = '/core/admin/users/reset-password'
    return ApiSender.post(route, data)
  }
}
