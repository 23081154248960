import ApiSender from './ApiSender'

export default class CurriculumUnitAPI {
  static getCurriculumUnits(query) {
    const route = '/core/admin/curriculum-units'
    return ApiSender.get(route, query)
  }
  static teacherGetCurriculumUnits(query) {
    const route = '/core/teacher/curriculums/list'
    return ApiSender.get(route, query)
  }
  static createCurriculumUnit(data) {
    const route = '/core/admin/curriculum-units'
    return ApiSender.post(route, data)
  }
  static getCurriculumUnit(data) {
    const route = '/core/admin/curriculum-units/' + data?.id
    return ApiSender.get(route)
  }

  static updateCurriculumUnit(data) {
    const route = '/core/admin/curriculum-units/' + data.id
    return ApiSender.put(route, data)
  }
  static deleteCurriculumUnit(data) {
    const route = '/core/admin/curriculum-units/' + data?.id
    return ApiSender.delete(route)
  }
}
