import {
  Switch,
  Route,
  Redirect,
  Link,
  useHistory,
  useLocation
} from 'react-router-dom'
import 'antd/dist/antd.css'
import './assets/styles/main.css'
import './assets/styles/responsive.css'
import * as store from './utils/storage'
import PrivateRoute from './pages/PrivateRoute'
import history from './utils/history'
import { AuthProvider, useAuthState } from './context/AuthContext'

import Main from './components/layout/Main'
import SignIn from './pages/SignIn'

import Dashboard from './pages/AdminPages/Dashboard'
import Profile from './pages/CommonPages/Profile'

import aAdmin from './pages/AdminPages/Admin'
import aSchool from './pages/AdminPages/School'
import aClass from './pages/AdminPages/Class'
import aClassGroup from './pages/AdminPages/ClassGroup'
import aTeacher from './pages/AdminPages/Teacher'
import aStudent from './pages/AdminPages/Student'
import aCurriculum from './pages/AdminPages/Curriculums'
import aCurriculumUnit from './pages/AdminPages/CurriculumUnit'
import aCalendar from './pages/AdminPages/Calendar'

import sCurriculums from './pages/StudentPages/Curriculum'
import sCalendar from './pages/StudentPages/Calendar'

import tClass from './pages/TeacherPages/Class'
import tCurriculum from './pages/TeacherPages/Curriculum'
import tCalendar from './pages/TeacherPages/Calendar'

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <Switch history={history}>
          <Route
            exact
            path="/"
            render={() => {
              return (
                <Redirect
                  to={(() => {
                    return '/profile'
                  })()}
                />
              )
            }}
          />
          <Route exact path="/login" component={SignIn} />
          <Main>
            <Switch>
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
              <PrivateRoute exact path="/profile" component={Profile} />

              <PrivateRoute exact path="/admin/admins" component={aAdmin} />
              <PrivateRoute exact path="/admin/schools" component={aSchool} />
              <PrivateRoute exact path="/admin/classes" component={aClass} />
              <PrivateRoute
                exact
                path="/admin/class-groups"
                component={aClassGroup}
              />
              <PrivateRoute exact path="/admin/teachers" component={aTeacher} />
              <PrivateRoute exact path="/admin/students" component={aStudent} />
              <PrivateRoute
                exact
                path="/admin/curriculums"
                component={aCurriculum}
              />
              <PrivateRoute
                exact
                path="/admin/curriculum-units"
                component={aCurriculumUnit}
              />
              <PrivateRoute
                exact
                path="/admin/calendar"
                component={aCalendar}
              />

              <PrivateRoute
                exact
                path="/student/curriculums"
                component={sCurriculums}
              />
              <PrivateRoute
                exact
                path="/student/calendar"
                component={sCalendar}
              />

              {/* <PrivateRoute exact path="/teacher/classes" component={tClass} /> */}
              <PrivateRoute
                exact
                path="/teacher/class/:id"
                component={tCurriculum}
              />
              <PrivateRoute
                exact
                path="/teacher/curriculums"
                component={tCurriculum}
              />
              <PrivateRoute
                exact
                path="/teacher/calendar"
                component={tCalendar}
              />
            </Switch>
          </Main>
        </Switch>
      </AuthProvider>
    </div>
  )
}

export default App
