import ApiSender from './ApiSender'

export default class ClassGroupAPI {
  static getClassGroups(query) {
    const route = '/core/admin/class_groups'
    return ApiSender.get(route, query)
  }
  static createClassGroup(data) {
    const route = '/core/admin/class_groups'
    return ApiSender.post(route, data)
  }
  static getClassGroup(data) {
    const route = '/core/admin/class_groups/' + data?.id
    return ApiSender.get(route)
  }
  static updateClassGroup(data) {
    const route = '/core/admin/class_groups/' + data.id
    return ApiSender.put(route, data)
  }
  static deleteClassGroup(data) {
    const route = '/core/admin/class_groups/' + data?.id
    return ApiSender.delete(route)
  }
}
