import store from 'store'

const NAME_SPACE = 'i2g.'

export const get = (key) => {
  const realKey = NAME_SPACE + key

  return store.get(realKey)
}

export const set = (key, data) => {
  const realKey = NAME_SPACE + key

  return store.set(realKey, data)
}

export const clear = (key) => {
  const realKey = NAME_SPACE + key

  return store.remove(realKey)
}

export const clearAll = () => {
  const rememberStatus = localStorage.getItem('remember.status')
  const rememberUsername = localStorage.getItem('remember.username')
  const rememberPassword = localStorage.getItem('remember.password')
  store.clearAll()
  localStorage.setItem('remember.status', rememberStatus)
  localStorage.setItem('remember.username', rememberUsername)
  localStorage.setItem('remember.password', rememberPassword)
}
