import ApiSender from './ApiSender'

export default class SchoolAPI {
  static getSchools(query) {
    const route = '/core/admin/schools'
    return ApiSender.get(route, query)
  }
  static createSchool(data) {
    const route = '/core/admin/schools'
    return ApiSender.post(route, data)
  }
  static getSchool(data) {
    const route = '/core/admin/schools/' + data?.id
    return ApiSender.get(route)
  }
  static updateSchool(data) {
    const route = '/core/admin/schools/' + data.id
    return ApiSender.put(route, data)
  }
  static deleteSchool(data) {
    const route = '/core/admin/schools/' + data?.id
    return ApiSender.delete(route)
  }
}
