import { Card } from 'antd'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import RootCalendar from '../../components/calendar/RootCalendar'
import {
  fetchAdminCalendar,
  fetchCurriculumUnits,
  fetchTeachers,
  getTitle
} from '../../components/calendar/event-utils'

function Calendar() {
  const [calendars, setCalendars] = useState([])
  const [teachers, setTeachers] = useState([])
  const [curriculumUnits, setCurriculumUnits] = useState([])
  useEffect(() => {
    fetchTeachers().then(setTeachers)
    fetchCurriculumUnits().then(setCurriculumUnits)
  }, [])
  const fetchInitCalendar = useCallback(async (range) => {
    const te = teachers
    const cu = curriculumUnits
    if (!te.length || !cu.length) return
    const ca = await fetchAdminCalendar(range)
    const initCalendars = await ca.map((i) => ({
      id: i.id.toString(),
      title: getTitle(te, cu, i),
      start: moment(i.start_time).format().replace(/\+.*$/, ''),
      end: moment(i.end_time).format().replace(/\+.*$/, ''),
      extendedProps: {
        curriculum_unit_id: i.curriculum_unit_id,
        teacher_id: i.teacher_id,
        teacher_check_in: i.teacher_check_in,
        teacher_check_out: i.teacher_check_out
      }
    }))
    setCalendars(initCalendars)
  }, [teachers, curriculumUnits])
  return (
    <Card className="tablespace mb-24">
      <RootCalendar
        calendars={calendars}
        fetchInitData={fetchInitCalendar}
        fetchCurriculumUnits={fetchCurriculumUnits}
      />
    </Card>
  )
}
export default Calendar
