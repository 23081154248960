import ApiSender from './ApiSender'

export default class CalendarAPI {
  static getAdminCalendars(query) {
    const route = '/core/admin/calendars'
    return ApiSender.get(route, query)
  }
  static getStudentCalendars(query) {
    const route = '/core/student/calendars'
    return ApiSender.get(route, query)
  }
  static getTeacherCalendars(query) {
    const route = '/core/teacher/calendars'
    return ApiSender.get(route, query)
  }
  static createCalendar(data) {
    const route = '/core/admin/calendars'
    return ApiSender.post(route, data)
  }
  static getAdminCalendar(data) {
    const route = '/core/admin/calendars/' + data?.id
    return ApiSender.get(route)
  }

  static updateCalendar(data) {
    const route = '/core/admin/calendars/' + data.id
    return ApiSender.put(route, data)
  }
  static deleteCalendar(data) {
    const route = '/core/admin/calendars/' + data?.id
    return ApiSender.delete(route)
  }
}
