import {
  Button,
  Card,
  Col,
  Form,
  List,
  Modal,
  Row,
  Space,
  Table,
  Tag
} from 'antd'

import { useCallback, useEffect, useState } from 'react'
import CurriculumAPI from '../../api/CurriculumAPI'
import MaterialViewModal from '../../components/MaterialViewModal'

function Curriculum() {
  const [form] = Form.useForm()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [modalType, setModalType] = useState('ADD')
  const [selected, setSelected] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(0)
  const [search, setSearch] = useState('')
  const [visibleSlideModal, setVisibleSlideModal] = useState(false)
  const [selectedMaterial, setSelectedMaterial] = useState(null)

  const toggleVisibleSlideModal = (v) => {
    setVisibleSlideModal(v)
  }

  const slideView = async (item) => {
    toggleVisibleSlideModal(true)
    setSelectedMaterial(item)
  }

  const viewContent = async (item) => {
    toggleModal(false)
    setSelected(item)
    slideView(item)
  }

  const fetchData = useCallback(
    async (query) => {
      setLoading(true)
      CurriculumAPI.studentGetCurriculums({
        page_number: query?.page_number || pageNumber,
        page_size: query?.page_size || pageSize,
        search: query?.search || search
      })
        .then((res) => {
          setTotal(res.count)
          setData(
            res.rows.map((item) => ({
              ...item,
              key: item.id
            }))
          )
          setLoading(false)
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false))
    },
    [pageNumber, pageSize, search]
  )

  const toggleModal = (v) => {
    setVisible(v)
  }

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const handleChangePagination = useCallback(
    (page_number, page_size) => {
      setPageNumber(page_number)
      setPageSize(page_size)
      fetchData({
        page_number,
        page_size,
        search: search
      })
    },
    [fetchData, search]
  )

  const unitColumns = [
    {
      title: 'STT',
      dataIndex: 'id',
      key: 'id',
      width: '5%',
      render: (text, record, index) => index + 1
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      render: (text, record) => text
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '20%',
      render: (text, record) => text
    },
    {
      title: 'Materials',
      dataIndex: 'curriculums_materials',
      key: 'curriculums_materials',
      width: '50%',
      render: (text, record) => (
        <List
          // style={{ overflow: 'auto' }}
          size="small"
          dataSource={text}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Button
                  type="primary"
                  onClick={() =>
                    viewContent(item)
                  }
                >
                  View
                </Button>
              ]}
            >
              {item.name}
            </List.Item>
          )}
        />
      )
    }
  ]
  const columns = [
    {
      title: 'STT',
      dataIndex: 'id',
      key: 'id',
      width: '5%',
      render: (text, record, index) => index + 1
    },
    {
      title: 'Name',
      dataIndex: 'curriculum',
      key: 'curriculum',
      width: '20%',
      render: (text, record) => text?.name
    },
    {
      title: 'Description',
      dataIndex: 'curriculum',
      key: 'curriculum',
      width: '20%',
      render: (text, record) => text?.description
    },
    {
      title: 'Class',
      dataIndex: 'class',
      key: 'class',
      width: '20%',
      render: (text, record) => text?.name
    },
    {
      title: 'Teachers',
      dataIndex: 'class',
      key: 'class',
      width: '20%',
      render: (text, record) =>
        text?.teachers?.map((item) => item.full_name).join(', ')
    },
    {
      title: 'Units',
      dataIndex: 'curriculum',
      key: 'curriculum',
      width: '20%',
      render: (text, record) => (
        <Tag color="cyan">{text?.curriculums_units?.length + ' units'}</Tag>
      )
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      width: '20%',
      align: 'center',
      render: (value, record) => {
        return (
          <Space>
            <Button
              type="primary"
              size="small"
              onClick={() => {
                setSelected(record)
                toggleModal(true)
              }}
            >
              View Units
            </Button>
          </Space>
        )
      }
    }
  ]
  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Curriculums table"
              extra={null}
            >
              <div className="table-responsive">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={data}
                  className="ant-border-space"
                  pagination={{
                    position: ['topCenter', 'bottomCenter'],
                    total,
                    pageSize,
                    current: pageNumber,
                    onChange: handleChangePagination,
                    showSizeChanger: true,
                    onShowSizeChange: handleChangePagination,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`
                  }}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <Modal
        title="Curriculum Units"
        open={visible}
        onCancel={() => toggleModal(false)}
        onOk={() => form.submit()}
        width={'100%'}
      >
        <Table
          scroll={{ y: 500, x: 1000 }}
          dataSource={selected?.curriculum?.curriculums_units}
          columns={unitColumns}
          className="ant-border-space"
          pagination={false}
        />
      </Modal>
      <MaterialViewModal
        open={visibleSlideModal}
        onCancel={() => toggleVisibleSlideModal(false)}
        material={selectedMaterial}
      />
    </>
  )
}

export default Curriculum
