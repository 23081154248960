import ApiSender from './ApiSender'
import * as store from '../utils/storage'
export default class UploadAPI {
  static uploadFile(file) {
    const route = '/core/admin/materials/upload'
    const formData = new FormData()
    formData.append('file', file)
    return ApiSender.post(route, formData)
  }
  static deleteUploadFile(filePath) {
    const route = '/core/admin/materials/delete'
    return ApiSender.get(route, { path: filePath })
  }
  static getUploadParams() {
    const route = '/core/admin/materials/upload'
    const url = ApiSender.getApiRoot(route)
    const token = store.get('token') || ''
    return { url, token }
  }
  static uploadAvatar(file) {
    const route = '/core/admin/image/upload'
    const formData = new FormData()
    formData.append('file', file)
    return ApiSender.post(route, formData)
  }
}
