import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuthState } from '../context/AuthContext'

function PrivateRoute({ component: Component, ...rest }) {
  const { username } = useAuthState()

  return (
    <Route
      {...rest}
      render={(props) =>
        username ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login'
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
