import React, { memo } from 'react'
import { Select, Spin, Form } from 'antd'

import { useSelectAllOption } from '../MultipleSelect'

function ItemMultipleSelect({ label, name, rules, fetchOptions, ...props }) {
  const [getValueFromEvent, optionsWithAllOption, fetching, debounceFetcher] =
    useSelectAllOption(fetchOptions)
  return (
    <Form.Item
      getValueFromEvent={getValueFromEvent}
      label={label}
      name={name}
      rules={rules}
    >
      <Select
        showSearch
        filterOption={false}
        onSearch={debounceFetcher}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        mode="multiple"
        options={optionsWithAllOption}
        onClear={() => debounceFetcher('')}
        {...props}
      />
    </Form.Item>
  )
}
export default memo(ItemMultipleSelect)
