import React, { Component } from 'react'
import { Link, useHistory, Redirect } from 'react-router-dom'
import {
  Layout,
  Menu,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Switch
} from 'antd'

import { useAuthState, useAuthDispatch, doLogin } from '../context/AuthContext'

const { Title } = Typography
const { Content } = Layout

function Login() {
  const { username: loggedUser, status, error } = useAuthState()
  const dispatch = useAuthDispatch()
  const [remember, setRemember] = React.useState(localStorage.getItem('remember.status') === 'true')
  const [form] = Form.useForm()
  if (localStorage.getItem('remember.status') === 'true') {
    form.setFieldsValue({
      remember: true,
      username: localStorage.getItem('remember.username'),
      // password: localStorage.getItem('remember.password')
    })
  }
  // form.setFieldsValue({ remember, username: 'admin@clevertech.edu.vn', password: '123456' })
  if (loggedUser) return <Redirect to="/profile" />

  const onFinish = async (values) => {
    if (remember) {
      localStorage.setItem('remember.status', true)
      localStorage.setItem('remember.username', values.username)
      // localStorage.setItem('remember.password', values.password)
    } else {
      localStorage.setItem('remember.status', false)
      localStorage.setItem('remember.username', '')
      localStorage.setItem('remember.password', '')
    }
    doLogin(dispatch, { ...values, remember })
  }

  function onChange(checked) {
    setRemember(checked)
  }
  const onFinishFailed = async (errorInfo) => {
    console.log('Failed:', errorInfo)
  }
  return (
    <>
      <Layout className="layout-default layout-signin">
        <Content className="signin">
          <Row gutter={[24, 0]} justify="space-around">
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 6, offset: 2 }}
              md={{ span: 12 }}
            >
              <Title className="mb-15">Sign In</Title>
              <Title className="font-regular text-muted" level={5}>
                Enter your username and password to sign in
              </Title>
              <Form
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                className="row-col"
              >
                <Form.Item
                  className="username"
                  name="username"
                  label="Username"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your username!'
                    }
                  ]}
                >
                  <Input placeholder="Username" />
                </Form.Item>

                <Form.Item
                  className="password"
                  name="password"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!'
                    }
                  ]}
                >
                  <Input placeholder="Password" type="password" />
                </Form.Item>

                <Form.Item className="aligin-center" valuePropName="checked">
                  <Switch onChange={onChange} checked={remember} />
                  Remember me
                </Form.Item>

                <Form.Item>
                  <Button
                    name="remember"
                    type="primary"
                    htmlType="submit"
                    style={{ width: '100%' }}
                  >
                    SIGN IN
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  )
}

export default Login
