import React from 'react'
import history from '../utils/history'
import * as store from '../utils/storage'
import UserAPI from '../api/UserAPI'

const AuthStateContext = React.createContext()
const AuthDispatchContext = React.createContext()

function reducer(currentState, newState) {
  return { ...currentState, ...newState }
}

function useAuthState() {
  const context = React.useContext(AuthStateContext)
  if (!context) throw new Error('useAuthState must be used in AuthProvider')

  return context
}

function useAuthDispatch() {
  const context = React.useContext(AuthDispatchContext)
  if (!context) throw new Error('useAuthDispatch must be used in AuthProvider')

  return context
}

const initialState = {
  status: 'idle',
  username: null,
  remember: false,
  token: null,
  refresh_token: null,
  error: null,
}

function getInitialState() {
  const username = store.get('username')
  const remember = store.get('remember')
  const token = store.get('token')
  const refresh_token = store.get('refresh_token')
  const myState = Object.assign(initialState, {
    username,
    remember,
    token,
    refresh_token
  })
  return myState
}

function AuthProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, getInitialState())
  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {props.children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  )
}

async function doLogin(dispatch, user) {
  try {
    dispatch({ status: 'pending' })
    const _user = await UserAPI.login(user)
    store.set('username', _user.username)
    store.set('remember', user.remember)
    store.set('token', _user.token)
    store.set('refreshToken', _user.refresh_token || 'my_refresh_token')
    dispatch({
      status: 'resolved',
      username: user.username,
      remember: user.remember,
      token: _user.token,
      refresh_token: _user.refresh_token || 'my_refresh_token',
      error: null
    })
  } catch (error) {
    dispatch({ status: 'rejected', error })
    console.log(error)
  }
}

async function doLogout(dispatch) {
  store.clearAll()
  dispatch(initialState)
  history.push('/login')
  window.location.reload()
}

export { AuthProvider, useAuthState, useAuthDispatch, doLogin, doLogout }
