import React, { useEffect } from 'react'
import {
  Modal,
  Form,
  Input,
  notification,
  DatePicker
} from 'antd'
import UserAPI from '../../api/UserAPI'
import moment from 'moment'

const UpdateProfile = ({ info, open, close, fetchData }) => {
  // const { formatMessage: tr } = useIntl();
  const [dateOfbirth, setDateOfBirth] = React.useState(
    moment(info?.date_of_birth || '09/09/1099', 'DD-MM-YYYY')
  )
  const [form] = Form.useForm()
  form.setFieldsValue(info)
  const onSave = () => {
    try {
      form.validateFields().then(async () => {
        const date = moment(dateOfbirth).format('DD/MM/YYYY')
        let data = form.getFieldsValue()
        data ={...data, date_of_birth: date}
        UserAPI.updateProfile(data, info?.id)
          .then(() => {
            notification['success']({
              message: 'Success',
              description: 'Update successfully'
            })
            fetchData()
          })
          .catch((err) =>
            notification['error']({
              message: 'Error',
              description: err.message
            })
          )
          .finally(() => close())
      })
    } catch (error) {
      notification['error']({
        message: 'Error',
        description: error.message
      })
    }
  }
  const onchangeDate = (value) => {
    setDateOfBirth(value)
  }
  useEffect(() => {
    if (info) {
      form.setFieldsValue(info)
      setDateOfBirth(moment(info?.date_of_birth, 'DD-MM-YYYY'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info])
  return (
    <Modal
      title="Update Profile"
      open={open}
      onClose={close}
      onOk={onSave}
      onCancel={close}
      okText="Update"
    >
      <Form
        form={form}
        onFinish={onSave}
        {...{
          labelCol: { span: 7 },
          wrapperCol: { span: 14 }
        }}
      >
        {/* <Form.Item label="Email" name="email">
          <Input disabled />
        </Form.Item>
        <Form.Item label="username" name="username">
          <Input disabled />
        </Form.Item> */}
        <Form.Item
          label="Full Name"
          name="full_name"
          rules={[{ required: true, message: 'Require' }]}
        >
          <Input />
        </Form.Item>
        {/* <Form.Item label="DoB" name="date_of_birth">
          <Input type="date" format={dateFormat} />
        </Form.Item> */}
        <Form.Item label="DoB">
          <DatePicker
            value={dateOfbirth}
            format="DD-MM-YYYY"
            onChange={onchangeDate}
          />
        </Form.Item>
        <Form.Item
          label="Mobile"
          name="phone"
          rules={[{ required: true, message: 'Require' }]}
        >
          <Input type="phone" />
        </Form.Item>
        <Form.Item label="Address" name="address">
          <Input />
        </Form.Item>
        <Form.Item label="Location" name="location">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default UpdateProfile
