import ApiSender from './ApiSender'

export default class CurriculumMaterialAPI {
  static getCurriculumMaterials(query) {
    const route = '/core/admin/curriculum-materials'
    return ApiSender.get(route, query)
  }
  static createCurriculumMaterial(data) {
    const route = '/core/admin/curriculum-materials'
    return ApiSender.post(route, data)
  }
  static getCurriculumMaterial(data) {
    const route = '/core/admin/curriculum-materials/' + data?.id
    return ApiSender.get(route)
  }
  static updateCurriculumMaterial(data) {
    const route = '/core/admin/curriculum-materials/' + data.id
    return ApiSender.put(route, data)
  }
  static deleteCurriculumMaterial(data) {
    const route = '/core/admin/curriculum-materials/' + data?.id
    return ApiSender.delete(route)
  }
}
