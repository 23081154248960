import React from 'react'
import { Card } from 'antd'
function Calendar() {
  return (
    <Card className="criclebox tablespace mb-24">
      {/* <RootCalendar
        onlyView
        calendars={calendars}
      /> */}
    </Card>
  )
}
export default Calendar
