import ApiSender from './ApiSender'

export default class StudentAPI {
  static getStudents(query) {
    const route = '/core/admin/students'
    return ApiSender.get(route, query)
  }
  static createStudent(data) {
    const route = '/core/admin/students'
    return ApiSender.post(route, data)
  }
  static getStudent(data) {
    const route = '/core/admin/students/' + data?.id
    return ApiSender.get(route)
  }
  static updateStudent(data) {
    const route = '/core/admin/students/' + data.id
    return ApiSender.put(route, data)
  }
  static deleteStudent(data) {
    const route = '/core/admin/students/' + data?.id
    return ApiSender.delete(route)
  }
  static exportStudents(data) {
    console.log(data)
    const route =
      data.role === 'admin'
        ? '/core/admin/students/export'
        : '/core/teacher/students/export'
    return ApiSender.download(route, { class_id: data.class_id })
  }
  static importStudents(file, data) {
    const route =
      data.role === 'admin'
        ? '/core/admin/students/import'
        : '/core/teacher/students/import'
    const formData = new FormData()
    formData.append('file', file)
    formData.append('class_id', data.class_id)
    return ApiSender.post(route, formData)
  }
}
