import ApiSender from './ApiSender'

export default class CurriculumAPI {
  static getCurriculums(query) {
    const route = '/core/admin/curriculums'
    return ApiSender.get(route, query)
  }
  static createCurriculum(data) {
    const route = '/core/admin/curriculums'
    return ApiSender.post(route, data)
  }
  static getCurriculum(data) {
    const route = '/core/admin/curriculums/' + data?.id
    return ApiSender.get(route)
  }
  static updateCurriculum(data) {
    const route = '/core/admin/curriculums/' + data.id
    return ApiSender.put(route, data)
  }
  static deleteCurriculum(data) {
    const route = '/core/admin/curriculums/' + data?.id
    return ApiSender.delete(route)
  }
  static studentGetCurriculums(query) {
    const route = '/core/student/curriculums'
    return ApiSender.get(route, query)
  }
  static teacherGetCurriculums(query) {
    const route = '/core/teacher/curriculums'
    return ApiSender.get(route, query)
  }
}
