import React, { useState, useRef, useMemo, useEffect, memo } from 'react'
import { Select, Spin } from 'antd'
import debounce from 'lodash/debounce'

function DebounceSelect({
    fetchOptions,
    debounceTimeout = 300,
    initialFetch = true,
    ...props
}) {
    const [fetching, setFetching] = useState(false)
    const [options, setOptions] = useState([])
    const fetchRef = useRef(0)

    const debounceFetcher = useMemo(() => {
        const loadOptions = (value) => {
            fetchRef.current += 1
            const fetchId = fetchRef.current
            setOptions([])
            setFetching(true)

            fetchOptions(value).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return
                }

                setOptions(newOptions)
                setFetching(false)
            })
        }

        return debounce(loadOptions, debounceTimeout)
    }, [fetchOptions, debounceTimeout])

    useEffect(() => {
        debounceFetcher.cancel()
    }, [debounceFetcher])

    useEffect(() => {
        if (initialFetch) {
            debounceFetcher('')
            debounceFetcher.flush()
        }
        return () => {
            debounceFetcher.cancel()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Select
            showSearch
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size='small' /> : null}
            {...props}
            options={options}
        />
    )
}
export default memo(DebounceSelect)
